import React from 'react';
import styled from '@emotion/styled';
// import { motion } from 'framer-motion';

const ArrowRightIcon = styled.svg({});

const ArrowRightIconComponent = ({ colour = '#fff', ...props }) => (
    <ArrowRightIcon
        viewBox="0 0 9 11"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M8.61 5.848a1.112 1.112 0 01-.458.832l-6.07 3.949c-.203.125-.41.191-.621.2a.963.963 0 01-.504-.142c-.328-.187-.492-.464-.492-.832.008-.398.18-.69.515-.878l4.817-3.13L1.12 2.837a.575.575 0 01-.058-.035c-.399-.219-.598-.52-.598-.903 0-.18.047-.351.14-.515.196-.32.48-.48.856-.48.117 0 .289.046.516.14l6.175 3.95a.999.999 0 01.457.855z"
            fill={colour}
        />
    </ArrowRightIcon>
);

export default ArrowRightIconComponent;
