import React, { useRef } from 'react';
import { motion, useMotionValue, useTransform, useSpring } from 'framer-motion';
import { NavLink } from 'react-router-dom';
import useRaf from '@rooks/use-raf';
import styled from '@emotion/styled';
import { mq } from 'styles/breakpoints';
import { useApplicationState } from 'data/context/application';
import slugFromString from 'utils/slugFromString';

const baseWidth = 10;
const distanceLimit = baseWidth * 50;
const beyondTheDistanceLimit = distanceLimit + 1;
const distanceInput = [
    -distanceLimit,
    -distanceLimit / 3.25,
    -distanceLimit / 10,
    0,
    distanceLimit / 10,
    distanceLimit / 3.25,
    distanceLimit,
];
const widthOutput = [
    baseWidth,
    baseWidth * 3,
    baseWidth * 10,
    baseWidth * 22,
    baseWidth * 10,
    baseWidth * 3,
    baseWidth,
];
const opacityOutput = [0, 0, 1, 1, 1, 0, 0];

// Note: Do not put willChange here - too many layers for safari
const Thumbnail = styled(motion.div)(({ hasTouch }) => ({
    width: hasTouch ? '70px !important' : 'auto',
    opacity: hasTouch ? '1 !important' : 0,
    [mq.large]: {},
}));

const Link = styled(NavLink)({
    display: 'flex',
    alignItems: 'flex-start',
    cursor: 'pointer',
    pointerEvents: 'all',
    position: 'relative',
    '::before': {
        content: '""',
        display: 'block',
        width: '100%',
        height: '100%',
        position: 'absolute',
        zIndex: 2,
        background: `rgba(0,0,0,0.1)`,
    },
});

const ThumbnailIcon = styled(motion.img)(
    {
        width: '100%',
        path: {
            fill: `rgba(0, 0, 0, 0)`,
        },
    },
    ({ colour }) => ({
        background: colour ? `${colour}` : 'hotpink',
    })
);

function ThumbnailComponent({
    colour,
    mouseX,
    number,
    category,
    imgUrl,
    imgAlt,
    current,
    winOffset,
    title,
}) {
    const { hasTouch } = useApplicationState();
    const distance = useMotionValue(beyondTheDistanceLimit);
    const opacity = useTransform(distance, distanceInput, opacityOutput);
    const momentUrl = `/moment/${number}/${slugFromString(title)}`;

    const width = useSpring(
        useTransform(distance, distanceInput, widthOutput),
        {
            // velocity: 10,
            mass: 12,
            damping: 1200,
            stiffness: 15000,
            // restDelta: 10,
            restSpeed: 10,
        }
    );

    const ref = useRef();

    useRaf(() => {
        if (hasTouch) {
            return false;
        }
        const el = ref.current;
        const mouseXVal = Math.round(mouseX.get());
        if (el && mouseXVal !== null) {
            const rect = el.getBoundingClientRect();

            // get the x coordinate of the img DOMElement's center
            // the left x coordinate plus the half of the width
            const imgCenterX = rect.left - winOffset + rect.width / 2;

            // difference between the x coordinate value of the mouse pointer
            // and the img center x coordinate value
            const distanceDelta = mouseXVal - imgCenterX;

            distance.set(distanceDelta);
            return;
        }

        distance.set(beyondTheDistanceLimit);
    }, true);

    return (
        <Thumbnail
            hasTouch={hasTouch}
            ref={ref}
            style={{ width, opacity }}
            onMouseEnter={() => {
                current.set(number);
            }}
        >
            <Link to={momentUrl}>
                <ThumbnailIcon
                    colour={colour}
                    src={imgUrl}
                    alt={imgAlt}
                ></ThumbnailIcon>
            </Link>
        </Thumbnail>
    );
}

export default ThumbnailComponent;
