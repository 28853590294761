import React from 'react';
import styled from '@emotion/styled';
import ReactMarkdown from 'react-markdown';
import GridTextCell from 'components/molecules/cell/text';
import { textCellMarkdown, gridCellTextContent } from 'styles/abstracts';
import { mq } from 'styles/breakpoints';

const GridContent = styled.div(gridCellTextContent, {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '90%',
    [mq.small]: {
        maxWidth: '80%',
    },
});
const ExploreText = styled(ReactMarkdown)(textCellMarkdown);

function ExploreComponent({ gridArea, explore }) {
    return (
        <GridTextCell gridArea={gridArea}>
            <GridContent>
                <ExploreText>{explore}</ExploreText>
            </GridContent>
        </GridTextCell>
    );
}

export default ExploreComponent;
