import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import styled from '@emotion/styled';
import { useInView } from 'react-intersection-observer';
import { useApplicationDispatch } from 'data/context/application';
import MenuButton from 'components/molecules/header/menuLink';
import { aspectRatioCell } from 'styles/abstracts';
import { namedColours } from 'styles/colours';
// import randomNumber from 'utils/randomNumber';
import { gridIntro, gridOutro } from 'styles/animation';

const GridMenuCell = styled(motion.div)(
    { ...aspectRatioCell },
    {
        width: '100%',
        height: '100%',
        perspective: '100vw',
        overflow: 'hidden',
        zIndex: 1,
        gridArea: 'MenuLink',
    }
);

const GridMenuLink = styled(motion.div)({
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: namedColours.gold,
    transformOrigin: `center center`,
});

const gridMenuLinkVariants = {
    initial: { scale: 0 },
    visible: {
        scale: 1,
        transition: {
            ...gridIntro,
            delay: 0.5,
        },
    },
    exit: {
        scale: 0,
        transition: {
            ...gridOutro,
            delay: 0.2,
        },
    },
};

function GridMenuLinkComponent() {
    // const delay = randomNumber(0, 1);
    const dispatch = useApplicationDispatch();
    const [ref, inView] = useInView({
        triggerOnce: false,
        initialInView: true,
    });

    useEffect(() => {
        if (inView) {
            // console.log('menu-in');
            dispatch({
                type: 'MENU_ICON_IN_VIEW',
            });
        } else {
            dispatch({
                type: 'MENU_ICON_OUT_VIEW',
            });
        }
    }, [inView, dispatch]);

    return (
        <GridMenuCell>
            <GridMenuLink ref={ref} variants={gridMenuLinkVariants}>
                <MenuButton
                    colour={namedColours.white}
                    hoverColour={namedColours.black}
                />
            </GridMenuLink>
        </GridMenuCell>
    );
}

export default GridMenuLinkComponent;
