import React from 'react';
import { useQuery } from '@apollo/client';
import GET_HOME from 'data/queries/get-home.graphql.js';
import Home from 'components/templates/home';
import SEO from 'utils/seo';
import slugFromString from 'utils/slugFromString';
import getMomentsFromCategories from 'utils/getMomentsFromCategories';

// Attach number property to moment
// Attach additional moments for grid design
function makeGridMoments(moments) {
    const momentsWithNumber = moments.map((moment, i) => {
        return {
            ...moment,
            number: i + 1,
        };
    });
    const moreMoments = [
        ...momentsWithNumber,
        ...momentsWithNumber.slice(0, 23),
    ];
    return moreMoments;
}

// We aren't going to want to render this on every page?
const GridContainer = ({ gridMoments, location: { pathname } }) => {
    const { loading, data } = useQuery(GET_HOME);

    if (loading) {
        return false;
        // TODO: Loading page (MAKE GLOBAL FIRST)
        // return <LoaderPage />;
    }

    const rawMoments = data ? data.home.grid : [];
    const categoryOrder = data ? data.home.categoryOrder : [];

    const orderedMoments = getMomentsFromCategories(categoryOrder, rawMoments);
    const moments = makeGridMoments(orderedMoments);
    const exploreLink = `/moment/1/${slugFromString(moments[0].title)}`;
    const homePageData = {
        title: data.home.title,
        ctaText: data.home.ctaText,
        ctaButtonText: data.home.ctaButtonText,
        ctaUrl: data.home.ctaUrl,
        introduction: data.home.introduction,
        about: data.home.about,
        explore: data.home.explore,
        articles: data.home.articles,
        exploreLink,
        moments: gridMoments,
    };

    const seoProps = {
        pageUrl: pathname,
        seoDefaults: data ? data._site.globalSeo : {},
        pageSeo: data ? data.home.seoMeta : {},
    };

    return (
        <>
            <SEO {...seoProps} />
            <Home {...homePageData} />
        </>
    );
};

export default GridContainer;
