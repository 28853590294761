import gql from 'graphql-tag';

const GET_HOME = gql`
    {
        _site {
            globalSeo {
                siteName
                titleSuffix
                fallbackSeo {
                    title
                    description
                    twitterCard
                    image {
                        url
                        alt
                    }
                }
            }
        }
        home {
            id
            title
            introduction
            about
            explore
            ctaText
            ctaButtonText
            ctaUrl
            categoryOrder {
                name
            }
            grid {
                id
                title
                body
                category {
                    name
                }
                svg {
                    url
                    alt
                }
                theme {
                    colour {
                        hex
                    }
                }
            }
            articles {
                title
                slug
                linkText
                heroIllustration {
                    alt
                    url
                }
                theme {
                    colour {
                        hex
                    }
                }
            }
            seoMeta {
                title
                description
                image {
                    url
                    alt
                }
                twitterCard
            }
        }
    }
`;

export default GET_HOME;
