import React from 'react';
import styled from '@emotion/styled';
import { useApplicationDispatch } from 'data/context/application';
import MenuIcon from 'components/atoms/menu';
import { namedColours } from 'styles/colours';

const MenuLink = styled.div({
    pointerEvents: 'all',
    cursor: 'pointer',
});
const MenuButton = styled(MenuIcon)(({ colour, hoverColour }) => ({
    width: '33px',
    path: {
        transition: `all 0.3s ease`,
        fill: colour,
    },
    polygon: {
        transition: `all 0.3s ease`,
        fill: colour,
    },
    ':hover': {
        path: {
            fill: hoverColour,
        },
        polygon: {
            fill: hoverColour,
        },
    },
}));

function MenuLinkComponent({
    colour = namedColours.black,
    hoverColour = namedColours.gold,
}) {
    const dispatch = useApplicationDispatch();
    return (
        <MenuLink
            onClick={() =>
                dispatch({
                    type: 'MENU_TOGGLE',
                })
            }
        >
            <MenuButton colour={colour} hoverColour={hoverColour} />
        </MenuLink>
    );
}

export default MenuLinkComponent;
