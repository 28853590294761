import React from 'react';
import { Redirect, Switch, Route, withRouter } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Menu from 'data/containers/menu';
import Home from 'data/containers/home';
import Moment from 'data/containers/moment';
import MomentBackground from 'data/containers/momentBackground';
import Longform from 'data/containers/longform';

function AppComponent({ location, gridMoments }) {
    const pathname = location.pathname;
    const section = pathname.split('/')[1] ? pathname.split('/')[1] : 'home';
    return (
        <>
            <AnimatePresence custom={section} exitBeforeEnter>
                <Switch location={location} key={location.pathname}>
                    <Route exact path="/moment/:id/:slug" component={Moment} />
                    <Route exact path="/article/:slug" component={Longform} />
                </Switch>
            </AnimatePresence>
            <AnimatePresence exitBeforeEnter>
                <Switch location={location} key={location.pathname}>
                    <Route
                        exact
                        path="/"
                        render={(routeProps) => (
                            <Home gridMoments={gridMoments} {...routeProps} />
                        )}
                    />
                    <Route exact path="/moment/:id/:slug" />
                    <Route exact path="/article/:slug" />
                    <Redirect to="/" />
                </Switch>
            </AnimatePresence>

            <AnimatePresence custom={section}>
                <Switch location={location} key={location.pathname}>
                    <Route
                        exact
                        path="/moment/:id/:slug"
                        component={MomentBackground}
                    />
                </Switch>
            </AnimatePresence>

            <Menu />
        </>
    );
}

export default withRouter(AppComponent);
