import React from 'react';
import { motion } from 'framer-motion';
import styled from '@emotion/styled';
import { mq } from 'styles/breakpoints';

const CIRCLE_SIZES = {
    small: 3,
    medium: 3,
    large: 9,
};

const largeInterval = 10;
const mediumInterval = 5;

const Line = styled(motion.div)({
    position: 'absolute',
    // top: '0px',
    left: '0',
    width: '100%',
    height: '1px',
    background: `rgba(255, 255, 255, 0.4)`,
});
const Points = styled.div({
    position: 'absolute',
    // top: '0',
    left: '0',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
});
const DotPoint = styled.div(
    {
        position: 'relative',
        display: 'block',
        background: 'white',
    },
    ({ size, opacity = 1 }) => {
        const circleSize = CIRCLE_SIZES[size];
        return {
            top: `-${circleSize / 2}px`,
            width: `${circleSize}px`,
            height: `${circleSize}px`,
            opacity,
            borderRadius: `${circleSize}px`,
        };
    }
);

const LinePoint = styled.div(
    {
        position: 'relative',

        background: 'white',
        display: 'none',
        [mq.large]: {
            display: 'block',
        },
    },
    ({ width, height, opacity = 1 }) => {
        return {
            top: `-${height / 2}px`,
            width: `${width}px`,
            height: `${height}px`,
            opacity,
        };
    }
);

function LineComponent({ moments }) {
    return (
        <>
            <Line />
            <Points>
                {moments.map((_, i) => {
                    // Space element at large interval, not first or last
                    if (
                        i % largeInterval === largeInterval - 1 &&
                        i !== moments.length - 1
                    ) {
                        return <DotPoint key={i} size={'large'} />;
                    }
                    // Space element at medium interval, not first or last
                    if (
                        i % mediumInterval === mediumInterval - 1 &&
                        i !== moments.length - 1
                    ) {
                        return <DotPoint key={i} size={'medium'} />;
                    }
                    // First or last
                    if (i === 0 || i === moments.length - 1) {
                        return <DotPoint key={i} size={'medium'} />;
                    }
                    // Otherwise

                    return (
                        <LinePoint
                            key={i}
                            // size={size}
                            height={7}
                            width={1}
                            opacity={0.3}
                        />
                    );
                })}
            </Points>
        </>
    );
}

export default LineComponent;
