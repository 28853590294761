import { useEffect } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { useApplicationDispatch } from 'data/context/application';

function useTrackedHistory() {
    const history = createBrowserHistory();
    const dispatch = useApplicationDispatch();

    useEffect(() => {
        dispatch({ type: 'HISTORY_PUSH', data: history.location.pathname });
        const unlisten = history.listen((location) => {
            dispatch({ type: 'HISTORY_PUSH', data: location.pathname });
        });
        return () => {
            unlisten();
        };
    }, [history, dispatch]);

    return history;
}

export { Router, useTrackedHistory };
