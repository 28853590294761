import React from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { NavLink } from 'react-router-dom';
import WGCLogo from 'components/atoms/logo';
import { mq } from 'styles/breakpoints';
import { namedColours } from 'styles/colours';

const LogoContainer = styled(motion.div)({});
const LogoLink = styled(NavLink)({
    pointerEvents: 'all',
});
const Logo = styled(WGCLogo)(({ colour, hoverColour }) => ({
    width: '90px',
    path: {
        transition: `all 0.3s ease`,
        fill: colour,
    },
    polygon: {
        transition: `all 0.3s ease`,
        fill: colour,
    },
    ':hover': {
        path: {
            fill: hoverColour,
        },
        polygon: {
            fill: hoverColour,
        },
    },
    [mq.medium]: {
        width: '120px',
    },
}));

function LogoLinkComponent({
    colour = namedColours.black,
    hoverColour = namedColours.gold,
    ...props
}) {
    return (
        <LogoContainer>
            <LogoLink to={`/`}>
                <Logo colour={colour} hoverColour={hoverColour} {...props} />
            </LogoLink>
        </LogoContainer>
    );
}

export default LogoLinkComponent;
