// Get moments in the order of the category array
// Append any moments that have categories not in the categories array
export default function getMomentsFromCategories(categories, moments) {
    const categoryMoments = [];
    categories.forEach((category) => {
        const categoryMomentsInCategory = moments.filter((moment) => {
            return moment.category.name === category.name;
        });
        categoryMoments.push(...categoryMomentsInCategory);
    });

    // Add moments that are not in the categories array
    const momentsNotInCategories = moments.filter((moment) => {
        return !categoryMoments.some((categoryMoment) => {
            return categoryMoment.id === moment.id;
        });
    });
    categoryMoments.push(...momentsNotInCategories);

    return categoryMoments;
}
