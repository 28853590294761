import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { motion, useAnimation } from 'framer-motion';
import CloseLink from 'components/molecules/menu/closeLink';
import ExternalLink from 'components/molecules/externalLink';
import CategoryLink from 'components/molecules/menu/categoryLink';
import {
    useApplicationDispatch,
    useApplicationState,
} from 'data/context/application';
import { namedColours } from 'styles/colours';
import { gutter, gutterValue } from 'styles/gutter';
import { menuSectionTitle } from 'styles/text';
import { menuIn, menuOut } from 'styles/animation';
import { useHistory } from 'react-router-dom';
import usePrevious from 'utils/usePrevious';
import { mq } from 'styles/breakpoints';

const MenuContainer = styled(motion.div)({
    position: 'absolute',
    top: '0',
    right: '0%',
    zIndex: '10',

    flexDirection: 'column',
    maxHeight: '100%',
});

const containerVariants = {
    initial: {
        x: '100%',
        display: 'none',
    },
    open: {
        x: '0%',
        display: 'flex',
        clipPath: `inset(0% 0% 0% 0%  round  0 0 0 75px)`,
        transition: menuIn,
    },
    close: {
        x: '100%',
        clipPath: `inset(0% 0% 0% 0%  round  0 0 0 0px)`,
        transition: menuOut,
        transitionEnd: {
            display: 'none',
        },
    },
};

const MenuScrollContainer = styled(motion.div)(({ colour }) => ({
    height: '100%',
    overflow: 'auto',
    background: colour,
    color: namedColours.white,
}));

const Menu = styled.div(
    {
        width: 'fit-content',
        fontSize: '32px',
        lineHeight: '48px',
        letterSpacing: '-0.27',
    },
    gutter({ top: 2, right: 1, bottom: 1, left: 1 })
);

const MenuButtons = styled(motion.div)(
    {
        position: 'absolute',
        top: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'flex-end',
    },
    gutter({ top: 1, right: 1 })
);

const LinkSection = styled(motion.div)(
    {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    gutter({ right: 0, left: 0 })
);

const FooterSection = styled(motion.div)(
    ({ colour }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        background: `linear-gradient(90deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.1) 100%), ${colour}`,
    }),
    gutter({ top: 1, right: 1, bottom: 2, left: 1 }, { large: { bottom: 1.5 } })
);

const SectionTitle = styled(motion.div)(menuSectionTitle, {
    width: '66%',
    paddingBottom: '3px',
    cursor: 'default',
    marginBottom: gutterValue('medium', 0.25),
    [mq.large]: {
        marginBottom: gutterValue('large', 0.5),
    },
});

function MenuComponent({ colour, articles, categories }) {
    const { menu } = useApplicationState();
    const dispatch = useApplicationDispatch();
    const animControls = useAnimation();
    const { location } = useHistory();
    const prevLocation = usePrevious(location); // Store previous in a ref

    // Toggle on application state
    useEffect(() => {
        if (menu) {
            animControls.start('open');
        } else {
            animControls.start('close');
        }
    }, [menu, animControls]);

    // Close on location change
    useEffect(() => {
        if (location !== prevLocation) {
            animControls.start('close');
            dispatch({
                type: 'MENU_CLOSE',
            });
        }
    }, [location, prevLocation, animControls, dispatch, menu]);

    return (
        <MenuContainer
            initial="initial"
            animate={animControls}
            variants={containerVariants}
        >
            <MenuButtons>
                <CloseLink />
            </MenuButtons>
            <MenuScrollContainer colour={colour}>
                <Menu>
                    <LinkSection>
                        <SectionTitle>Choose a category</SectionTitle>
                        {categories.map(({ name, url, range }, i) => (
                            <CategoryLink key={i} url={url} range={range}>
                                {name}
                            </CategoryLink>
                        ))}
                    </LinkSection>
                </Menu>
            </MenuScrollContainer>
            <FooterSection colour={colour}>
                <ExternalLink
                    linkText="Invest.gold"
                    href="https://www.invest.gold/"
                />
            </FooterSection>
        </MenuContainer>
    );
}

export default MenuComponent;
