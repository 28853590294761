import styled from "@emotion/styled";
import React from "react";
import ExternalIcon from "components/atoms/external";
import { namedColours } from "styles/colours";
import { menuExternalLink } from "styles/text";

const ExternalLink = styled.a(menuExternalLink, {
  display: "flex",
  flexDirection: "row",
});

const Icon = styled.div({});

const Text = styled.div({
  color: namedColours.white,
  marginRight: "5px",
});

function ExternalLinkComponent({
  linkText,
  href,
  target = "_blank",
  hasIcon = true,
}) {
  return (
    <ExternalLink rel="noopener noreferrer" target={target} href={href}>
      <Text>{linkText}</Text>
      {hasIcon ? (
        <Icon>
          <ExternalIcon
            colour={namedColours.white}
            width="19px"
            height="19px"
          />
        </Icon>
      ) : null}
    </ExternalLink>
  );
}

export default ExternalLinkComponent;
