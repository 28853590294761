import React from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';

const WGCLogo = styled(motion.svg)({});

const WGCLogoComponent = ({ colour, ...props }) => {
    return (
        <WGCLogo
            viewBox="0 0 175 65"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <motion.path
                fill={colour}
                d="M32.1,0A32.1,32.1,0,1,0,64.2,32.1,32.1,32.1,0,0,0,32.1,0Zm0,61.44A29.34,29.34,0,1,1,61.44,32.1,29.34,29.34,0,0,1,32.1,61.44Zm0-54.37a25,25,0,1,0,25,25A25,25,0,0,0,32.09,7.06Zm0,47.31A22.28,22.28,0,1,1,54.37,32.1,22.28,22.28,0,0,1,32.1,54.37Zm0-40.25a18,18,0,1,0,18,18A18,18,0,0,0,32.1,14.12Zm0,33.19A15.21,15.21,0,1,1,47.31,32.1,15.21,15.21,0,0,1,32.09,47.31Z"
            />
            <motion.path
                fill={colour}
                d="M122.68,12.84A5,5,0,0,0,126.38,8a5.22,5.22,0,0,0-5.22-5.22h-7.84v16h2.82V13.21h3.53l3.94,5.59h3.32Zm-6.54-2.2V5.33h5A2.6,2.6,0,0,1,123.69,8a2.6,2.6,0,0,1-2.53,2.66Z"
            />
            <motion.path
                fill={colour}
                d="M129.46,2.76h2.82V16.23h8.09v2.57H129.46Z"
            />
            <motion.path
                fill={colour}
                d="M149.14,2.76h-6.23v16h6.23a8,8,0,1,0,0-16Zm0,13.47h-3.42V5.32h3.42a5.34,5.34,0,0,1,5.32,5.45A5.34,5.34,0,0,1,149.14,16.23Z"
            />
            <motion.path
                fill={colour}
                d="M110.51,24.08h2.82V37.55h8.09v2.57H110.51Z"
            />
            <motion.path
                fill={colour}
                d="M99.66,23.8A8.29,8.29,0,1,0,108,32.1,8.29,8.29,0,0,0,99.66,23.8Zm0,13.88a5.59,5.59,0,1,1,5.59-5.59A5.59,5.59,0,0,1,99.66,37.69Z"
            />
            <motion.path
                fill={colour}
                d="M102.45,2.49a8.29,8.29,0,1,0,8.29,8.29A8.29,8.29,0,0,0,102.45,2.49Zm0,13.88A5.59,5.59,0,1,1,108,10.78,5.59,5.59,0,0,1,102.45,16.37Z"
            />
            <motion.path
                fill={colour}
                d="M96.87,45.12a8.29,8.29,0,1,0,8.29,8.29A8.29,8.29,0,0,0,96.87,45.12Zm0,13.88a5.59,5.59,0,1,1,5.59-5.59A5.59,5.59,0,0,1,96.87,59Z"
            />
            <motion.path
                fill={colour}
                d="M87.23,59.28a8.29,8.29,0,1,1,0-11.73l-1.91,1.91a5.59,5.59,0,1,0,0,7.91Z"
            />
            <motion.path
                fill={colour}
                d="M135.31,45.4h2.81v16h-2.4l-8.5-11.34V61.43h-2.81v-16h2.65l8.25,11Z"
            />
            <motion.path
                fill={colour}
                d="M163.29,45.4h2.82V58.87h8.09v2.57H163.29Z"
            />
            <motion.path fill={colour} d="M160.3,45.4v16h-2.82v-16Z" />
            <motion.path
                fill={colour}
                d="M154.87,59.28a8.29,8.29,0,1,1,0-11.73L153,49.46a5.59,5.59,0,1,0,0,7.91Z"
            />
            <motion.path
                fill={colour}
                d="M130.64,24.08H124.4v16h6.23a8,8,0,1,0,0-16Zm0,13.47h-3.42V26.65h3.42a5.45,5.45,0,0,1,0,10.9Z"
            />
            <motion.path
                fill={colour}
                d="M121.42,45.4h-2.81V55a4,4,0,1,1-8.1,0V45.4H107.7v9.45a6.86,6.86,0,1,0,13.72,0Z"
            />
            <motion.path
                fill={colour}
                d="M88.64,31.22v6.28a9.43,9.43,0,0,1-7,2.95,8.29,8.29,0,1,1-.13-16.58,9,9,0,0,1,6.18,2.43l-1.91,1.91a6.36,6.36,0,0,0-4.27-1.64,5.65,5.65,0,0,0-5.72,5.53,5.75,5.75,0,0,0,5.85,5.65A6.35,6.35,0,0,0,86,36.33V33.67h-4.1V31.22Z"
            />
            <motion.polygon
                fill={colour}
                points="78.69 18.79 76.26 18.79 71.06 2.76 73.96 2.76 77.48 14.15 81.14 2.76 83.52 2.76 87.18 14.15 90.7 2.76 93.6 2.76 88.39 18.79 85.97 18.79 82.33 7.71 78.69 18.79"
            />
        </WGCLogo>
    );
};

export default WGCLogoComponent;
