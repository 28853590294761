import React from 'react';
import { Switch, Route, withRouter, matchPath } from 'react-router-dom';
import { motion, useMotionValue } from 'framer-motion';
import styled from '@emotion/styled';
import LogoLink from 'components/molecules/header/logoLink';
import MenuLink from 'components/molecules/header/menuLink';
import Timeline from 'components/organisms/timeline';
import { outterGutter, gutter } from 'styles/gutter';
import { useApplicationState } from 'data/context/application';

const Header = styled(motion.div)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    position: 'absolute',
    width: '100vw',
    zIndex: 5,
    willChange: 'transform',
    pointerEvents: 'none',
}));

const headerVariants = {
    initial: {},
    visible: {
        transition: {
            staggerChildren: 0.5,
            // delayChildren: 1,
            staggerDirection: 1,
        },
    },
    exit: {},
};

const LogoContainer = styled(motion.div)(
    {
        position: 'relative',
        height: 'fit-content',
    },
    outterGutter()
);

const TimelineContainer = styled(motion.div)(
    {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        zIndex: 3,

        overflow: 'visible',
    },
    gutter()
);

const MenuButtonContainer = styled(motion.div)(
    {
        position: 'relative',
        height: 'fit-content',
        willChange: 'opacity',
    },
    outterGutter()
);
const childVariants = {
    initial: (prevPathnameMatch) => {
        if (prevPathnameMatch && prevPathnameMatch.isExact) {
            return {
                opacity: 1,
            };
        }
        return {
            opacity: 0,
        };
    },
    visible: {
        opacity: 1,
        transition: {
            duration: 2,
        },
    },
    exit: (section) => {
        if (section !== 'moment') {
            return {
                opacity: 0,
                transition: {
                    duration: 1,
                },
            };
        }
    },
};

function HeaderComponent({ moments, location }) {
    const iconColour = useMotionValue(`#fff`);
    const { navigationHistory } = useApplicationState();
    const pathToMatch = `/moment/:id/:slug`;
    const prevPathnameMatch =
        navigationHistory.length > 1
            ? matchPath(navigationHistory[navigationHistory.length - 2], {
                  path: pathToMatch,
                  exact: true,
              })
            : { params: { id: '0' } };

    return (
        <Header
            initial="initial"
            animate="visible"
            exit="exit"
            variants={headerVariants}
        >
            <LogoContainer custom={prevPathnameMatch} variants={childVariants}>
                <LogoLink colour={iconColour} />
            </LogoContainer>
            {moments && (
                <Switch location={location} key={location.pathname}>
                    <Route
                        exact
                        path="/moment/:id/:slug"
                        render={(routeProps) => (
                            <TimelineContainer
                                custom={prevPathnameMatch}
                                variants={childVariants}
                            >
                                <Timeline moments={moments} {...routeProps} />
                            </TimelineContainer>
                        )}
                    />
                </Switch>
            )}
            <MenuButtonContainer
                custom={prevPathnameMatch}
                variants={childVariants}
            >
                <MenuLink colour={iconColour} />
            </MenuButtonContainer>
        </Header>
    );
}

export default withRouter(HeaderComponent);
