import React, { useEffect } from 'react';
import { animate, motion, useAnimation } from 'framer-motion';
import styled from '@emotion/styled';
import MouseIcon from 'components/atoms/mouse';
import { gutter } from 'styles/gutter';

const ScrollIndicatorContainer = styled(motion.div)({
    position: 'absolute',
    zIndex: 6,
    width: '100%',
    bottom: '10px',
    height: 'auto',
    display: 'flex',
    justifyContent: 'center',
    pointerEvents: 'all',
    // opacity: 0,
});

const indicatorVariants = {
    initial: { opacity: 0, y: 10 },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            delay: 3,
            opacity: {
                duration: 1,
                delay: 2,
            },
            y: {
                duration: 1,
                delay: 3,
            },
        },
    },
    exit: {
        opacity: 0,
        y: 10,
        transition: {
            duration: 0.4,
        },
        transitionEnd: {
            display: 'none',
        },
    },
};

const MouseHitArea = styled.div(
    {
        cursor: 'pointer',
    },
    gutter({ top: 0.1, right: 0.1, bottom: 0.1, left: 0.1 })
);

function ScrollIndicatorComponent({ scrollYProgress, scrollContainer }) {
    const controls = useAnimation('initial');

    useEffect(() => {
        controls.start('visible');

        function hideIndicator() {
            unsubscribeY();
            controls.start('exit');
        }
        const unsubscribeY = scrollYProgress.onChange(hideIndicator);
        return () => {
            unsubscribeY();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function onClick() {
        animate(0, 220, {
            duration: 1,
            onUpdate: (v) => {
                return (scrollContainer.current.scrollTop = v);
            },
        });
    }

    return (
        <ScrollIndicatorContainer
            initial="initial"
            exit="exit"
            variants={indicatorVariants}
            animate={controls}
        >
            <MouseHitArea onClick={onClick}>
                <MouseIcon />
            </MouseHitArea>
        </ScrollIndicatorContainer>
    );
}

export default ScrollIndicatorComponent;
