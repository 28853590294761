const breakpoints = {
    // default: iPhone SE [320]
    small: 375, // iPhone 6
    medium: 768, // iPad
    large: 992, //  iPad pro & small desktop [1024]
    xLarge: 1366, // iPad pro landscape & medium desktop
    // xXLarge desktop [4k]
};

const mediaQueries = {
    small: `@media only screen and (min-width: ${breakpoints.small}px)`,
    medium: `@media only screen and (min-width: ${breakpoints.medium}px)`,
    large: `@media only screen and (min-width: ${breakpoints.large}px)`,
    xLarge: `@media only screen and (min-width: ${breakpoints.xLarge}px)`,
};

const mediaQueriesRev = {
    small: `@media only screen and (max-width: ${breakpoints.small + 1}px)`,
    medium: `@media only screen and (max-width: ${breakpoints.medium + 1}px)`,
    large: `@media only screen and (max-width: ${breakpoints.large + 1}px)`,
    xLarge: `@media only screen and (max-width: ${breakpoints.xLarge + 1}px)`,
};

export { mediaQueries as mq, mediaQueriesRev as mqr, breakpoints };

// WIP
// export const generatedBreakPoints = () => {
//     const mq = {};
//     for (let point in breakpoints) {
//         const value = breakpoints[point];
//         mq[point] = `@media only screen and (max-width: ${value}px)`;
//     }
//     return mq;
// };
