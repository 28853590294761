import React from 'react';
import styled from '@emotion/styled';
import ArticleLink from 'components/molecules/articleLink';
import { namedColours } from 'styles/colours';
import { gutter, outterGutter } from 'styles/gutter';
import { mq } from 'styles/breakpoints';

const ArticleLinks = styled.div(
    {
        width: '100%',

        background: namedColours.grey,
        [mq.large]: {
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gridAutoRows: '1fr',
        },
    },
    outterGutter({ top: 1, bottom: 1 })
);

const ArticleLinkContainer = styled.div(
    {
        width: '100%',
        height: '100%',
    },
    gutter()
);

function ArticleLinksComponent({ articles }) {
    return (
        <ArticleLinks>
            {articles.map((article, i) => (
                <ArticleLinkContainer key={i}>
                    <ArticleLink {...article} />
                </ArticleLinkContainer>
            ))}
        </ArticleLinks>
    );
}

export default ArticleLinksComponent;
