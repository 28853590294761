import React from 'react';
import styled from '@emotion/styled';
import { useApplicationDispatch } from 'data/context/application';
import CloseIcon from 'components/atoms/close';
import { namedColours } from 'styles/colours';

const CloseLink = styled.div({
    pointerEvents: 'all',
    cursor: 'pointer',
});
const CloseButton = styled(CloseIcon)(({ colour, hoverColour }) => ({
    width: '23px',
    path: {
        transition: `all 0.3s ease`,
        fill: colour,
    },
    polygon: {
        transition: `all 0.3s ease`,
        fill: colour,
    },
    ':hover': {
        path: {
            fill: hoverColour,
        },
        polygon: {
            fill: hoverColour,
        },
    },
}));

function CloseLinkComponent({
    colour = namedColours.white,
    hoverColour = namedColours.black,
}) {
    const dispatch = useApplicationDispatch();
    return (
        <CloseLink
            onClick={() =>
                dispatch({
                    type: 'MENU_CLOSE',
                })
            }
        >
            <CloseButton colour={colour} hoverColour={hoverColour} />
        </CloseLink>
    );
}

export default CloseLinkComponent;
