import React from 'react';
import styled from '@emotion/styled';
import ReactMarkdown from 'react-markdown';
import { mq } from 'styles/breakpoints';
import { gutter, gutterValue } from 'styles/gutter';
import { articleMarkdown } from 'styles/abstracts';
import { motion } from 'framer-motion';

const SectionOne = styled(motion.div)(
    {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        flexDirection: 'column',
        [mq.large]: {
            flexDirection: 'row',
        },
    },
    gutter()
);

const articleS1Variants = {
    initial: {
        y: 300,
    },
    visible: {
        y: 0,
        transition: {
            delay: 1.3,
            duration: 2,
            ease: [0.5, 0.5, 0, 1],
        },
    },
};

const ImageContainer = styled.div(
    {
        position: 'relative',
        width: '100%',
        height: '400px',
        marginTop: `-${gutterValue('xLarge', 0.75)}`,
        order: 1,
        // background: 'green',
        [mq.large]: {
            marginTop: `-${gutterValue('xLarge', 2.5)}`,
            width: '50%',
            order: 2,
            height: 'auto',
        },
    },
    gutter({ top: 0, bottom: 0, left: 1 })
);
const Image = styled.img({
    position: 'relative',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    [mq.large]: {
        position: 'absolute',
        width: '100%',
        maxWidth: '500px',
        height: '100%',
    },
});

const TextContainer = styled.div({
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    order: 2,
    [mq.large]: {
        order: 1,
        width: '50%',
    },
});
const Text = styled(ReactMarkdown)(
    articleMarkdown,
    {
        order: 2,

        [mq.large]: {
            order: 1,
            maxWidth: '500px',
        },
    },
    gutter(
        { top: 2.0, bottom: 0, left: 2.2 },
        { large: { top: 0.75, bottom: 3, left: 0 } }
    )
);

function SectionOneComponent({ imageUrl, imageAlt, text }) {
    return (
        <SectionOne
            initial="initial"
            animate="visible"
            exit="exit"
            variants={articleS1Variants}
        >
            <ImageContainer>
                <Image src={imageUrl} alt={imageAlt} />
            </ImageContainer>

            <TextContainer>
                <Text>{text}</Text>
            </TextContainer>
        </SectionOne>
    );
}

export default SectionOneComponent;
