import React from 'react';
import styled from '@emotion/styled';
import Article from 'components/organisms/article';

const ArticlePage = styled.div({
    position: 'absolute',
    zIndex: 1,
    width: '100vw',
    height: 'var(--vh, 100vh)',
    perspective: '100vw',
});

function ArticlePageComponent({ article }) {
    return (
        <ArticlePage>
            <Article {...article} />
        </ArticlePage>
    );
}

export default ArticlePageComponent;
