import styled from '@emotion/styled';
import React from 'react';
import Button from 'components/molecules/articleLink/button';
import { namedColours } from 'styles/colours';
import { gutter } from 'styles/gutter';
import { articleLinkShort, articleLinkTitle } from 'styles/text';
import { motion, useAnimation } from 'framer-motion';

// -------------------------
// Style
// -------------------------
const ArticleLink = styled(motion.div)({
    background: namedColours.white,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    textDecoration: 'none',
    color: namedColours.black,
});

const Image = styled.div(({ colour }) => ({
    background: colour,
    perspective: '100vw',
    perspectiveOrigin: 'top',
}));

const Illustration = styled(motion.img)({});

const Text = styled(motion.div)(
    {
        position: 'relative',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    gutter()
);
const Title = styled(motion.h2)(
    articleLinkTitle,
    { willChange: 'transform' },
    gutter({ top: 0, right: 0, left: 0 })
);
const Short = styled(motion.div)(
    articleLinkShort,
    {
        flexGrow: 1,
        willChange: 'transform',
    },
    gutter({ top: 0, right: 0, bottom: 1, left: 0 })
);
const TextForeground = styled(motion.div)({
    position: 'relative',
    zIndex: 2,
});
const TextBackground = styled(motion.div)({
    background: namedColours.white,
    position: 'absolute',
    zIndex: 1,
    width: '100%',
    height: '100%',
    bottom: 0,
    left: 0,
});

// -------------------------
// Animation
// -------------------------
const articleLinkVariants = {
    initial: {
        y: -100,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
    },
    exit: {
        opacity: 0,
    },
};

const illustrationMotion = {
    inition: {
        z: 0,
    },
    hover: {
        z: -200,
        transition: {
            type: 'spring',
            stiffness: 170,
            damping: 26,
            duration: 4,
        },
    },
    hoverEnd: {
        z: 0,
        transition: {
            type: 'spring',
            stiffness: 170,
            damping: 26,
            duration: 4,
        },
    },
};

const textVariants = {
    inition: {
        y: 0,
    },
    hover: {
        y: -35,
        transition: {
            type: 'spring',
            stiffness: 170,
            damping: 26,
            duration: 4,
        },
    },
    hoverEnd: {
        y: 0,
        transition: {
            type: 'spring',
            stiffness: 170,
            damping: 26,
            duration: 4,
        },
    },
};

// -------------------------
// Component
// -------------------------
function ArticleLinkComponent({
    heroIllustration: { url: imageUrl, alt: imageAlt },
    theme: {
        colour: { hex: colour },
    },
    title,
    slug,
    linkText,
}) {
    const animControls = useAnimation();
    animControls.start('visible');

    return (
        <ArticleLink
            initial="initial"
            animate={animControls}
            exit="exit"
            variants={articleLinkVariants}
        >
            <Image colour={colour}>
                <Illustration
                    src={imageUrl}
                    alt={imageAlt}
                    variants={illustrationMotion}
                />
            </Image>
            <Text>
                <TextForeground>
                    <Title variants={textVariants}>{title}</Title>
                    <Short variants={textVariants}>{linkText}</Short>

                    <Button
                        onHoverStart={() => animControls.start('hover')}
                        onHoverEnd={() => animControls.start('hoverEnd')}
                        to={`article/${slug}`}
                    >
                        Find out more
                    </Button>
                </TextForeground>
                <TextBackground variants={textVariants} />
            </Text>
        </ArticleLink>
    );
}

export default ArticleLinkComponent;
