import React from 'react';
import { motion, useTransform, useSpring } from 'framer-motion';
import styled from '@emotion/styled';

// const MAX_WIDTH = '1000';
const SCROLL_AT = 5;

const ImageStage = styled(motion.div)({
    position: 'absolute',
    zIndex: 2,
    width: '100vw',
    height: 'var(--vh, 100vh)',
    perspectiveOrigin: 'top center',
    perspective: '100vw',
    overflow: 'hidden',
    pointerEvents: 'none',
});

const ImageContainer = styled(motion.div)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
});

const containerVariants = {
    initial: { z: 200, y: 100, opacity: 0 },
    visible: {
        z: 0,
        y: 0,
        opacity: 1,
        transition: {
            delay: 0,
            duration: 0.75,
        },
    },
    exit: {
        opacity: 0,
        transition: {
            duration: 0.3,
        },
    },
};

const Image = styled(motion.img)({
    width: '100%',
    // maxWidth: `${MAX_WIDTH}px`,
    maxHeight: 'var(--vh, 100vh)',
    // objectFit: 'contain',
});

const imageVariants = {};

function ImageComponent({ scrollY, scrollYProgress, imgUrl, imgAlt, number }) {
    const physics = { damping: 15, mass: 0.27, stiffness: 55 };
    const imageZ = useSpring(
        useTransform(
            scrollYProgress,
            [0, 0.2, 0.25, 0.55, 1],
            [0, 0, -50, -50, -200]
        ),
        physics
    );

    const imageY = useSpring(
        useTransform(scrollY, (value) => {
            const vh =
                document.documentElement.style
                    .getPropertyValue('--vh')
                    .split('px')[0] * 1;

            if (value >= vh / SCROLL_AT) {
                const newY = -(value - vh / SCROLL_AT);
                return newY;
            } else {
                return 0;
            }
        }),
        physics
    );

    return (
        <ImageStage>
            <ImageContainer
                initial="initial"
                animate="visible"
                exit="exit"
                style={{
                    z: imageZ,
                    y: imageY,
                }}
                variants={containerVariants}
            >
                <Image
                    src={imgUrl}
                    alt={imgAlt}
                    layoutId={`moment-${number}-image`}
                    variants={imageVariants}
                />
            </ImageContainer>
        </ImageStage>
    );
}

export default ImageComponent;
