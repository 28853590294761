import React from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';

const Backgrounds = styled(motion.div)({
    position: 'absolute',
    top: 0,
    zIndex: -1,
    width: '100vw',
    height: 'var(--vh, 100vh)',
});
const MomentBackground = styled('div')(({ colour }) => ({
    position: 'absolute',
    top: 0,
    zIndex: 2,
    width: '100%',
    height: '100%',
    background: colour,
}));

function MomentBackgroundComponent({
    colour = 'hotpink',
    nextMoment: {
        theme: {
            colour: { hex: nextColour = 'hotpink' },
        },
    },
}) {
    return (
        <Backgrounds>
            <MomentBackground colour={colour} />
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: 1.2 } }}
                style={{
                    position: 'absolute',
                    top: 0,
                    zIndex: 3,
                    width: '100%',
                    height: '100%',
                    background: nextColour,
                }}
            />
        </Backgrounds>
    );
}

export default MomentBackgroundComponent;
