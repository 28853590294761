import { guttr } from 'guttr';
import { mq } from 'styles/breakpoints';

export const config = {
    base: {
        gutter: 28,
        unit: 'px',
    },
    breakpoints: {
        small: {
            gutter: 28,
            mediaQuery: mq.small,
        },
        medium: {
            gutter: 36,
            mediaQuery: mq.medium,
        },
        large: {
            gutter: 40,
            mediaQuery: mq.large,
        },
        xLarge: {
            gutter: 50,
            mediaQuery: mq.xLarge,
        },
    },
};

const gutter = guttr(config);

function gutterValue(breakpoint, multiplier = 0.5) {
    const value = config.breakpoints[breakpoint].gutter * multiplier;
    const unit = config.base.unit;

    return `${value}${unit}`;
}

export const outterConfig = {
    base: {
        gutter: 50,
    },
    breakpoints: {
        small: {
            medium: 50,
            mediaQuery: mq.small,
        },
        medium: {
            gutter: 70,
            mediaQuery: mq.medium,
        },
        large: {
            gutter: 90,
            mediaQuery: mq.large,
        },
        xLarge: {
            gutter: 90,
            mediaQuery: mq.xLarge,
        },
    },
};

const outterGutter = guttr(outterConfig);

export { gutter, gutterValue, outterGutter };
