import React from 'react';
import styled from '@emotion/styled';
import ReactMarkdown from 'react-markdown';
import { mq } from 'styles/breakpoints';
import { gutter, gutterValue } from 'styles/gutter';
import { articleMarkdown } from 'styles/abstracts';

const Outro = styled.div(
    {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        background: 'white',
        [mq.large]: {
            marginTop: `-${gutterValue('xLarge', 4.5)}`,
        },
    },
    gutter()
);

const TextContainer = styled.div({
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    [mq.large]: {
        maxWidth: '800px',
    },
});
const Text = styled(ReactMarkdown)(
    articleMarkdown,
    {
        [mq.large]: {
            maxWidth: '800px',
        },
    },
    gutter({ left: 0.25, right: 2.5, top: 1, bottom: 1 }, { large: { left: 1, right: 1, top: 4, bottom: 3 } })
);

function OutroComponent({ text }) {
    return (
        <Outro>
            <TextContainer>
                <Text>{text}</Text>
            </TextContainer>
        </Outro>
    );
}

export default OutroComponent;
