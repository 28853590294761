import React from 'react';
import styled from '@emotion/styled';
import ReactMarkdown from 'react-markdown';
import { mq } from 'styles/breakpoints';
import { gutter } from 'styles/gutter';
import { articleMarkdown } from 'styles/abstracts';

const SectionThree = styled.div(
    {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        flexDirection: 'column',
        [mq.large]: {
            flexDirection: 'row'
        },
    },
    gutter({ bottom: 2 })
);

const ImageContainer = styled.div(
    {
        position: 'relative',
        height: '314px',
        // marginTop: `-${gutterValue('xLarge', 2.5)}`,
        order: 1,
        // background: 'green',
        [mq.large]: {
            width: '57%',
            order: 1,
            height: 'auto',
        },
    },
    gutter({ top: 0, bottom: 0, left: 0, right: 0 }, {large: { top: 0, bottom: 0 }})
);
const Image = styled.img(
    {
        position: 'relative',
        right: 0,
        width: '100%',
        // height: `calc(100% + ${gutterValue('xLarge', 5)})`,
        height: '100%',
        objectFit: 'cover',
        [mq.large]: {
            position: 'absolute',
            width: '100%',
            maxWidth: '650px',
            height: '100%',
        },
    },
    gutter({left: .25, top: 0, right: 1.5, bottom: 0}, {large: { left: 1 }})
);

const TextContainer = styled.div({
    display: 'flex',
    justifyContent: 'flex-start',
    order: 2,
    [mq.large]: {
        order: 2,
        width: '43%',
    },
});
const Text = styled(ReactMarkdown)(
    articleMarkdown,
    {
        [mq.large]: {
            maxWidth: '500px',
        },
    },
    gutter({ top: 2, bottom: 0, left: 2 }, {large: { left: 1, bottom: 3.5}})
);

function SectionThreeComponent({ imageUrl, imageAlt, text }) {
    return (
        <SectionThree>
            <ImageContainer>
                <Image src={imageUrl} alt={imageAlt} />
            </ImageContainer>

            <TextContainer>
                <Text>{text}</Text>
            </TextContainer>
        </SectionThree>
    );
}

export default SectionThreeComponent;
