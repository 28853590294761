import React, { useEffect, useRef, useState } from 'react';
import {
    motion,
    useMotionValue,
    useTransform,
    useSpring,
    useAnimation,
} from 'framer-motion';
import styled from '@emotion/styled';
import Count from 'components/molecules/timeline/count';
import Line from 'components/molecules/timeline/line';
import Thumbnail from 'components/molecules/timeline/thumbnail';
import { gutter, gutterValue } from 'styles/gutter';
import { mq } from 'styles/breakpoints';
import { useApplicationState } from 'data/context/application';
import { breakpoints } from 'styles/breakpoints';
import { matchPath } from 'react-router';
import slugFromString from 'utils/slugFromString';

// const mediumInterval = 4;
const MAX_WIDTH = '700';

const Timeline = styled.div(({ hasTouch }) => ({
    position: 'absolute',
    bottom: '-10px',
    left: 0,
    width: '100%',
    // display: 'none',
    [mq.large]: {
        position: 'relative',
        display: 'block',
        maxWidth: `${MAX_WIDTH}px`,
    },
}));

const Ruler = styled(motion.div)(
    {
        position: 'relative',
        zIndex: 2,
        top: '2px',
        cursor: 'pointer',
        pointerEvents: 'all',
        [mq.large]: {
            top: '-6px',
        },
    },
    gutter()
);
const rulerVariants = {
    initial: (prevPathnameMatch) => {
        if (prevPathnameMatch && prevPathnameMatch.isExact) {
            return {
                opacity: 1,
                clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
            };
        }
        return {
            opacity: 0,
            clipPath: 'polygon(0 0, 0 0, 0 100%, 0% 100%)',
        };
    },
    visible: {
        opacity: 1,
        clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
        transition: { duration: 2, delay: 1.2, type: 'spring' },
    },
    // exit: {
    //     clipPath: 'polygon(100% 0, 100% 0, 100% 100%, 100% 100%)',
    //     transition: { duration: 0.4 },
    // },
};

const Carousel = styled(motion.div)(
    ({ hasTouch }) => ({
        position: 'absolute',
        zIndex: 1,
        top: hasTouch ? gutterValue('small') : `17px`,
        width: `100%`,
        display: 'flex',
        overflow: hasTouch ? 'hidden' : 'visible',

        [mq.medium]: {
            top: hasTouch ? gutterValue('medium') : `21px`,
        },
        [mq.large]: {
            // top: hasTouch ? gutterValue('xLarge') : '35px',
            top: '35px',
        },
        [mq.xLarge]: {
            // top: hasTouch ? gutterValue('xLarge') : '45px',
            top: '45px',
        },
    })
    // gutter({top})
);

const carouselVariants = {
    initial: {
        display: 'none',
        opacity: 0,
    },
    visible: {
        display: 'flex',
        opacity: 1,
    },
    hidden: {
        opacity: 0,
        transitionEnd: {
            display: 'none',
        },
    },
    exit: {
        opacity: 0,
        transitionEnd: {
            display: 'none',
        },
    },
};

const CarouselItems = styled(motion.div)(({ hasTouch }) => ({
    display: 'flex',
    // left: 0,
    // width: `100%`,
    [mq.large]: {
        position: 'relative',
        width: hasTouch ? `auto` : `120%`,
        left: hasTouch ? `0` : `-10%`,
        justifyContent: hasTouch ? 'flex-start' : 'center',
    },
}));

function TimelineComponent({
    match: {
        params: { category, id },
    },
    moments,
}) {
    const { hasTouch, navigationHistory } = useApplicationState();
    const rulerRef = useRef(null);
    const carouselRef = useRef(null);
    const [winOffset, setWinOffset] = useState(0);
    const [dragBounds, setDragBounds] = useState(0);
    const carouselControls = useAnimation('initial');
    const current = useMotionValue(0);
    const mouseX = useMotionValue(null);
    const mouseY = useMotionValue(0);
    const x = useMotionValue(0);
    const y = useSpring(useTransform(mouseY, [0, 1], [10, 0]), {
        damping: 35,
        stiffness: 250,
    });
    // const opacity = useSpring(useTransform(mouseY, [0, 1], [0, 1]));
    const pathToMatch = `/moment/:id/:slug`;
    const prevPathnameMatch =
        navigationHistory.length > 1
            ? matchPath(navigationHistory[navigationHistory.length - 2], {
                  path: pathToMatch,
                  exact: true,
              })
            : { params: { id: '0' } };
    const prevId = prevPathnameMatch ? prevPathnameMatch.params.id : '0';

    useEffect(() => {
        // TODO: Do we really need to get viewport width here?
        // Can we not use timelineWidth, saves potential repaint for value
        // Could also potentially ditch setting vw also... more performance
        function setDimensions() {
            let leftPos = 0;
            const width = carouselRef.current.offsetWidth;
            const timelineWidth = rulerRef.current.offsetWidth;
            const viewportWidth =
                getComputedStyle(document.documentElement)
                    .getPropertyValue('--vw')
                    .split('px')[0] * 1;
            const dragXBounds = width - timelineWidth;

            // If browser is mobile width
            if (viewportWidth < breakpoints.medium) {
                setDragBounds(dragXBounds);
            } else {
                setDragBounds(dragXBounds);
                if (!hasTouch) {
                    leftPos = rulerRef.current.getBoundingClientRect().left;
                    setWinOffset(leftPos);
                }
            }
        }

        setDimensions();
        window.addEventListener('resize', setDimensions);
        return () => {
            window.removeEventListener('resize', setDimensions);
        };
    }, [hasTouch]);

    // Normalise mouse position to Ruler
    function updateMouseX(event) {
        mouseX.set(event.nativeEvent.x - winOffset);
    }

    return (
        <Timeline hasTouch={hasTouch}>
            <Count number={id * 1} startNumber={prevId * 1} />
            <Ruler
                custom={prevPathnameMatch}
                onMouseEnter={() => {
                    if (!hasTouch) {
                        carouselControls.start('visible');
                        mouseY.set(1);
                    }
                }}
                onMouseLeave={() => {
                    carouselControls.start('hidden');
                    mouseY.set(0);
                }}
                onTouchStart={() => {
                    carouselControls.start('visible');
                    mouseY.set(1);
                }}
                onMouseMove={updateMouseX}
                ref={rulerRef}
                variants={rulerVariants}
            >
                <Line moments={moments} />
            </Ruler>
            {/* TODO: make carousel molecule */}
            <Carousel
                hasTouch={hasTouch}
                onMouseMove={updateMouseX}
                onMouseEnter={() => {
                    if (!hasTouch) {
                        mouseY.set(1);
                        carouselControls.start('visible');
                    }
                }}
                onMouseLeave={() => {
                    carouselControls.start('hidden');
                    mouseY.set(0);
                }}
                // onTouchStart={() => mouseY.set(1)}
                style={{ y }}
                initial="initial"
                exit="exit"
                variants={carouselVariants}
                animate={carouselControls}
            >
                <CarouselItems
                    hasTouch={hasTouch}
                    drag="x"
                    dragConstraints={{
                        left: -dragBounds,
                        right: 0,
                    }}
                    initial={{ x: 0 }}
                    style={{ x }}
                    ref={carouselRef}
                >
                    {moments.map(
                        (
                            {
                                title,
                                theme: {
                                    colour: { hex: colour },
                                },
                                svg: { url, alt },
                            },
                            i
                        ) => {
                            const categoryNavigation = category
                                ? slugFromString(moments[i].category.name)
                                : false;
                            return (
                                <Thumbnail
                                    key={i}
                                    title={title}
                                    number={i + 1}
                                    category={categoryNavigation}
                                    colour={colour}
                                    imgUrl={url}
                                    imgAlt={alt}
                                    mouseX={mouseX}
                                    current={current}
                                    winOffset={winOffset}
                                />
                            );
                        }
                    )}
                </CarouselItems>
            </Carousel>
        </Timeline>
    );
}

export default TimelineComponent;
