import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { getEnv } from 'env.js';
import { useAnalyticsStore } from 'data/context/analytics';
import styled from '@emotion/styled';
import { AnimatePresence, motion } from 'framer-motion';
import { useLocation } from 'react-router';
import { gutter } from 'styles/gutter';
import { namedColours } from 'styles/colours';
import { gridIntro, gridTextCellOutro } from 'styles/animation';
import { paragraph } from 'styles/text';
import { mq } from 'styles/breakpoints';

const GA_ID = getEnv('GA_ID');

const Notification = styled(motion.div)({
    position: 'fixed',
    bottom: 0,
    zIndex: '10',
    width: '100vw',
    background: namedColours.black,
    color: namedColours.white,
});

const notificationAnimation = {
    initial: {
        opacity: 0,
        y: '100%',
        transition: {
            ...gridTextCellOutro,
            duration: 0.4,
        },
    },
    visible: {
        opacity: 1,
        y: '0%',
        transition: {
            delay: 1.5,
            ...gridIntro,
        },
    },
};

const Contents = styled(motion.div)(
    {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    gutter()
);

const Message = styled(motion.div)(
    paragraph,
    {
        cursor: 'default',
    },
    gutter({ top: 0, right: 0.25, bottom: 0, left: 0 })
);
const LinkText = styled(motion.a)({
    display: 'inline-flex',
    position: 'relative',
    overflow: 'hidden',
    textDecoration: 'none',
    color: namedColours.white,
});

const Text = styled(motion.span)({});

const textVariants = {
    initial: {
        color: namedColours.white,
    },
    hover: {
        color: namedColours.gold,
    },
};

const textUnderlineAnim = {
    visible: {
        x: 0,
        transition: {
            ease: [0.2, 0.9, 0.15, 0.95],
            duration: 0.25,
        },
    },
    hover: {
        x: '105%',
        transition: {
            ease: 'easeOut',
            duration: 0.25,
        },
    },
};

const Options = styled(motion.div)({
    display: 'flex',
});
const Option = styled(motion.div)({
    overflow: 'hidden',
    position: 'relative',
    marginRight: '3px',
    cursor: 'pointer',
});

const OptionText = styled(motion.a)(paragraph);

const Underline = styled(motion.div)({
    position: 'absolute',
    bottom: '2px',
    left: 0,
    width: '100%',
    height: '1px',
    background: namedColours.gold,
});

const optionUnderlineAnim = {
    visible: {
        x: '-105%',
        transition: {
            ease: [0.2, 0.9, 0.15, 0.95],
            duration: 0.3,
        },
    },
    hover: {
        x: 0,
        transition: {
            ease: 'easeOut',
            duration: 0.3,
        },
    },
};

const Divider = styled(motion.div)({
    width: '1px',
    height: '17px',
    marginTop: '0px',
    background: namedColours.white,
    opacity: 0.3,
    marginLeft: '6px',
    marginRight: '8px',
    [mq.medium]: {
        height: '21px',
        marginTop: '-1px',
    },
});

function Tracking() {
    const { analytics, preferenceSaved, setAnalytics } = useAnalyticsStore();
    const location = useLocation();

    useEffect(() => {
        if (analytics) {
            window[`ga-disable-${GA_ID}`] = false;
            ReactGA.initialize(GA_ID);
        } else {
            window[`ga-disable-${GA_ID}`] = true;
        }
    }, [analytics]);

    useEffect(() => {
        if (!analytics) return false;

        ReactGA.set({ page: location.pathname });
        ReactGA.pageview(location.pathname);
    }, [analytics, location]);

    return (
        <AnimatePresence>
            {!preferenceSaved && (
                <Notification
                    initial="initial"
                    animate="visible"
                    exit="initial"
                    variants={notificationAnimation}
                >
                    <Contents>
                        <Message>
                            This website uses{' '}
                            <LinkText
                                initial="initial"
                                animate="visible"
                                whileHover="hover"
                                href="https://www.gold.org/cookies"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <Text variants={textVariants}>
                                    anonymous analytics cookies
                                </Text>
                                <Underline variants={textUnderlineAnim} />
                            </LinkText>
                            .
                        </Message>
                        <Options>
                            <Option
                                suggested
                                animate="visible"
                                whileHover="hover"
                                onClick={() => setAnalytics(true)}
                            >
                                <OptionText variants={textVariants}>
                                    OK
                                </OptionText>
                                <Underline variants={optionUnderlineAnim} />
                            </Option>
                            <Divider />
                            <Option
                                animate="visible"
                                whileHover="hover"
                                onClick={() => setAnalytics(false)}
                            >
                                <OptionText variants={textVariants}>
                                    Disable
                                </OptionText>
                                <Underline variants={optionUnderlineAnim} />
                            </Option>
                        </Options>
                    </Contents>
                </Notification>
            )}
        </AnimatePresence>
    );
}

export default Tracking;
