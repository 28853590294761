import React from 'react';
import { NavLink } from 'react-router-dom';

// Render React Router NavLink if passed a `to` prop
function UniversalLinkComponent({
    href,
    to,
    target = '_blank',
    children,
    ...props
}) {
    if (to) {
        return (
            <NavLink style={{ textDecoration: 'none' }} to={to} {...props}>
                {children}
            </NavLink>
        );
    }

    return (
        <a
            style={{ textDecoration: 'none' }}
            href={href}
            rel="noopener noreferrer"
            target={target}
            {...props}
        >
            {children}
        </a>
    );
}

export default UniversalLinkComponent;
