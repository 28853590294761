import React from 'react';
import styled from '@emotion/styled';
import { motion, useTransform } from 'framer-motion';
import { namedColours } from 'styles/colours';
import { momentTitle, momentNumber, momentCategory } from 'styles/text';
import { momentMarkdown } from 'styles/abstracts';
import { mq } from 'styles/breakpoints';
import ReactMarkdown from 'react-markdown';
import { gutter, gutterValue, outterGutter } from 'styles/gutter';

const Text = styled.div({
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    color: namedColours.white,
    textAlign: 'left',
});

const TextContent = styled(motion.div)(
    {
        // maxWidth: '280px',
        // paddingRight: gutterValue('medium', 2),
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        userSelect: 'none',
        position: 'relative',

        [mq.large]: {
            boxSizing: 'content-box',
            paddingRight: '0px',
            maxWidth: '600px',
        },
        [mq.xLarge]: {
            paddingRight: '260px',
            // paddingRight: gutterValue('xLarge', 2),
        },
    },
    outterGutter({ bottom: 2, right: 0.25 }, { large: { bottom: 2.5 } })
);

const Header = styled.div(
    {
        display: 'flex',
        flexDirection: 'column',
        paddingRight: gutterValue('small', 2),
        [mq.large]: {
            paddingRight: 0,
        },
    },
    gutter({
        top: 0,
        right: 2,
        bottom: 0,
        left: 0,
    })
);

const Title = styled(motion.h1)(momentTitle, {
    order: 2,
    width: '100%',
    maxWidth: `${600}px`,
});
const Context = styled.div(
    {
        display: 'flex',
        alignItems: 'flex-end',
        position: 'relative',
        width: '100%',
    },
    gutter({ top: 0, right: 0, bottom: 0.25, left: 0 })
);
const Number = styled(motion.span)(
    momentNumber,
    {
        [mq.large]: {
            position: 'absolute',
            right: '100%',
            top: '-5px',
            marginRight: gutterValue('xLarge'),
        },
        [mq.xLarge]: {
            marginRight: gutterValue('xLarge'),
        },
    },
    gutter(
        { top: 0, right: 0.5, bottom: 0, left: 0 },
        { small: { top: 0, right: 0.7, bottom: 0, left: 0 } }
    )
);

const Category = styled(motion.h2)(momentCategory, {
    order: 1,
});

const Separator = styled(motion.div)({
    display: 'none',
    [mq.large]: {
        display: 'block',
        position: 'absolute',
        right: '0px',
        top: '3px',
        width: '1px',
        height: '100px',
        background: namedColours.white,
    },
});

const Body = styled(ReactMarkdown)(momentMarkdown, {
    order: 3,
    wordBreak: 'break-word',
});

function TextComponent({ title, number, category, body, overScroll }) {
    const opacity = useTransform(overScroll, [0, -150], [1, 0]);
    return (
        <Text>
            <TextContent style={{ opacity }}>
                <Header>
                    <Title>{title}</Title>
                    <Context>
                        <Number>
                            {number}
                            <Separator />
                        </Number>
                        <Category>{category}</Category>
                    </Context>
                </Header>
                <Body>{body}</Body>
            </TextContent>
        </Text>
    );
}

export default TextComponent;
