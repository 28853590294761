import React, { useState, useEffect } from 'react';
import { useMotionValue, motion, animate, useTransform } from 'framer-motion';
import styled from '@emotion/styled';
import { namedColours } from 'styles/colours';
import { mq } from 'styles/breakpoints';
// import { useMotionValueState } from 'utils/useMotionValueState';

const Count = styled(motion.div)({
    position: 'absolute',
    top: '-14px',
    width: 'auto',
    color: namedColours.white,
    display: 'none',
    [mq.large]: {
        display: 'block',
    },
});
const Number = styled.div({
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-end',
    left: '-50%',
});
const Current = styled(motion.div)({
    marginRight: '4px',
    fontWeight: 'bold',
    fontSize: '17px',
    lineHeight: '21px',
});
const Of = styled.div({
    fontSize: '13px',
});

function CountComponent({ number, startNumber }) {
    // Create the MotionValue
    const x = useMotionValue(startNumber);
    // Transform for the count position
    const xPos = useTransform(x, (value) => value * 14);

    // Set-up React state for the MotionValue - this will be initalised as `0`
    const [xState, setXState] = useState(x.get());

    // Trigger the animation
    useEffect(() => {
        const controls = animate(x, number, {
            duration: 2,
            delay: 1,
        });

        return controls.stop;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Bind the state's set function to the MotionValue. It's important we return
    // the unsubscribe function so when the component unmounts it unsubscribes
    // the state from the MotionValue.
    // This could be written as
    // useEffect(() => x.onChange(setXState), [])
    // for short.
    useEffect(() => {
        const unsubscribe = x.onChange((v) => {
            // console.log('onchange', Math.round(v));
            setXState(Math.round(v));
        });
        return unsubscribe;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Count style={{ x: xPos }} transition={{ duration: 0.5 }}>
            <Number>
                <Current>{`${xState} `}</Current>
                <Of> of 50</Of>
            </Number>
        </Count>
    );
}

export default CountComponent;
