export const namedColours = {
    white: '#E9E9E9',
    grey: '#CCCCCC',
    black: '#1b1d1f',
    gold: '#A39161',
    darkRed: '#660023',
    red: '#ba1126',
    darkPurple: '#350f4f',
    purple: '#7A3271',
    darkGreen: '#0f4430',
    green: '#2ea350',
};
