import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import {
    animate,
    motion,
    useAnimation,
    useElementScroll,
    useMotionTemplate,
    useMotionValue,
    useTransform,
} from 'framer-motion';
import Text from 'components/molecules/moment/text';
import Image from 'components/molecules/moment/image';
import ScrollIndicator from 'components/molecules/moment/scrollIndicator';
import Navigation from 'components/molecules/moment/navigation';
import { DRAG_THESHOLD_SCROLL, DRAG_THESHOLD_TOUCH } from 'styles/constants';
import { useGesture } from 'react-use-gesture';
import { useDebouncedCallback } from 'use-debounce/lib';
import { useState } from '@hookstate/core';
import { useHistory } from 'react-router-dom';
import { useApplicationState } from 'data/context/application';

const CurrentMoment = styled(motion.div)({}, ({ colour }) => ({
    position: 'relative',
    zIndex: 3,
    width: '100vw',
    height: 'var(--vh, 100vh)',
    background: colour ? `${colour}` : 'hotpink',
    overflow: 'visible',
    willChange: 'transform, clipPath',
}));

const currMomentVariants = {
    initial: {},
    visible: {},
    exit: (section) => {
        if (section === 'moment') {
            return {
                clipPath: `inset(0% 0% 0% 0%  round 0 0 10vw 10vw)`,
            };
        }
    },
};

const ScrollContainer = styled(motion.div)({
    position: 'absolute',
    zIndex: 3,
    width: '100vw',
    height: 'var(--vh, 100vh)',
    overflow: 'auto',
    maskImage: `linear-gradient(0deg, rgba(9,9,121,0) 0%, rgba(9,9,121,1) 5%, rgba(2,0,36,1) 20%, rgba(9,9,121,1) 80%, rgba(0,212,255,0) 90%)`,
});
const ScrollContent = styled.div({
    marginTop: 'var(--vh, 100vh)',
});

function isEndOfScrollContainer(node) {
    return node.offsetHeight + node.scrollTop >= node.scrollHeight;
}

function MomentComponent({
    number,
    nextNumber,
    prevNumber,
    nextUrl,
    prevUrl,
    colour,
    imgUrl,
    imgAlt,
    title,
    category,
    body,
}) {
    const {
        push,
        location: { pathname },
    } = useHistory();
    const { hasTouch } = useApplicationState();
    const threshold = hasTouch ? DRAG_THESHOLD_TOUCH : DRAG_THESHOLD_SCROLL;
    const navigationTimeout = hasTouch ? 0 : 300;
    const section = pathname.split('/')[1] ? pathname.split('/')[1] : 'home';
    const ref = useRef(null);
    const animState = useState(false);
    const footerOpen = useState(false);
    const { scrollY, scrollYProgress } = useElementScroll(ref);
    const navigationControls = useAnimation();
    const y = useMotionValue(0);
    const dragY = useTransform(y, [100, 0, 0, threshold], [0, 0, 0, threshold]);
    const roundedCornerVals = useTransform(
        y,
        [0, 0, threshold / 2, threshold],
        [0, 0, 10, 10]
    );
    const roundedCorners = useMotionTemplate`inset(0% 0% 0% 0%  round 0 0 ${roundedCornerVals}vw ${roundedCornerVals}vw)`;
    const bind = useGesture(
        {
            onDrag: (state) => {
                if (animState.get()) {
                    return false;
                }
                if (isEndOfScrollContainer(ref.current) && footerOpen.get()) {
                    const movementValue = state.movement[1];

                    if (movementValue <= threshold) {
                        animState.set(true);
                        navigationControls.start('exit');
                        push(nextUrl);
                    } else {
                        animate(y, movementValue);
                    }
                }
            },
            onDragEnd: () => {
                if (animState.get()) {
                    return false;
                }
                if (isEndOfScrollContainer(ref.current)) {
                    animate(y, 0);
                }
            },
            onWheel: (state) => {
                if (animState.get()) {
                    return false;
                }
                if (isEndOfScrollContainer(ref.current)) {
                    const movementValue = -state.movement[1] / 10;

                    if (movementValue <= threshold && footerOpen.get()) {
                        animState.set(true);
                        navigationControls.start('exit');
                        push(nextUrl);
                    } else {
                        animate(y, movementValue);
                    }
                }
            },
            onWheelEnd: () => {
                if (animState.get()) {
                    return false;
                }
                if (isEndOfScrollContainer(ref.current)) {
                    animate(y, 0);
                }
            },
        },
        {
            drag: {
                useTouch: true,
            },
        }
    );

    const atMomentBottom = useDebouncedCallback(
        (scrollYProgress) => {
            if (animState.get()) {
                return false;
            }
            if (isEndOfScrollContainer(ref.current)) {
                // Delay footer open to allow time for navigation to animate in
                setTimeout(() => {
                    footerOpen.set(true);
                }, navigationTimeout);
                navigationControls.start('visible');
            } else {
                if (footerOpen.get()) {
                    footerOpen.set(false);
                    navigationControls.start('hidden');
                }
            }
        },
        200,
        { leading: true }
    );

    useEffect(() => {
        if (animState.get()) {
            return false;
        }
        const unsubscribeScrollYProgress =
            scrollYProgress.onChange(atMomentBottom);

        return () => {
            unsubscribeScrollYProgress();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <CurrentMoment
                custom={section}
                key={`moment_${number}`}
                initial="initial"
                exit="exit"
                colour={colour}
                style={{
                    y: dragY,
                    clipPath: roundedCorners,
                }}
                variants={currMomentVariants}
            >
                <Image
                    scrollYProgress={scrollYProgress}
                    scrollY={scrollY}
                    imgUrl={imgUrl}
                    imgAlt={imgAlt}
                    number={number}
                />
                <ScrollContainer ref={ref} {...bind()}>
                    <ScrollContent>
                        <Text
                            title={title}
                            number={number}
                            category={category}
                            body={body}
                            overScroll={dragY}
                        />
                    </ScrollContent>
                </ScrollContainer>
                <ScrollIndicator
                    scrollYProgress={scrollYProgress}
                    scrollContainer={ref}
                />
            </CurrentMoment>
            <Navigation
                number={number}
                nextNumber={nextNumber}
                prevNumber={prevNumber}
                nextUrl={nextUrl}
                prevUrl={prevUrl}
                colour={colour}
                scrollYProgress={scrollYProgress}
                dragPosY={y}
                controls={navigationControls}
            />
        </>
    );
}

export default MomentComponent;
