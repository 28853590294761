import React from 'react';
import styled from '@emotion/styled';
import { gutter } from 'styles/gutter';
import { namedColours } from 'styles/colours';
import { button } from 'styles/text';
import { motion } from 'framer-motion';
import { NavLink } from 'react-router-dom';

const Button = styled(motion.div)(
    {
        background: namedColours.black,

        width: `fit-content`,
        transition: `background 0.4s ease`,
        ':hover': {
            background: namedColours.gold,
        },
    },
    gutter({ top: 0.2, right: 0.5, bottom: 0.2, left: 0.5 })
);

const ButtonLink = styled(NavLink)({
    color: namedColours.white,
    textDecoration: 'none',
});
const Text = styled.span(button);

function ArticleButtonComponent({ children, to, ...props }) {
    return (
        <Button {...props}>
            <ButtonLink to={to}>
                <Text>{children}</Text>
            </ButtonLink>
        </Button>
    );
}

export default ArticleButtonComponent;
