import gql from 'graphql-tag';

const GET_MOMENTS = gql`
    {
        _site {
            globalSeo {
                siteName
                titleSuffix
                fallbackSeo {
                    title
                    description
                    twitterCard
                    image {
                        url
                        alt
                    }
                }
            }
        }
        home {
            id
            articles {
                id
                title
                slug
                introduction
                heroIllustration {
                    alt
                    url
                }
                theme {
                    colour {
                        hex
                    }
                }
                sectionOneImage {
                    alt
                    url
                }
                sectionOneText
                sectionTwoQuote
                sectionTwoText
                sectionThreeImage {
                    alt
                    url
                }
                sectionThreeText
                outroText
                seoMeta {
                    title
                    description
                    image {
                        url
                        alt
                    }
                    twitterCard
                }
            }
        }
    }
`;

export default GET_MOMENTS;
