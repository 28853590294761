import styled from '@emotion/styled';
import React from 'react';
import { CTAText } from 'styles/text';
import Button from 'components/molecules/button';
import { gutter } from 'styles/gutter';

const HomePageCTA = styled.div(
    {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    gutter({ top: 0, bottom: 3 })
);

const Text = styled.p(
    CTAText,
    {
        maxWidth: '620px',
    },
    gutter({})
);

function HomePageCTAComponent({ text, buttonText, buttonUrl }) {
    return (
        <HomePageCTA>
            <Text>{text}</Text>
            <Button href={buttonUrl}>{buttonText}</Button>
        </HomePageCTA>
    );
}

export default HomePageCTAComponent;
