import { css } from '@emotion/react';
import { paragraph, articleQuote, gridParagraphs } from './text';
import { namedColours } from './colours';
import { mq } from 'styles/breakpoints';
import { gutterValue } from 'styles/gutter';

export const aspectRatioCell = css({
    position: 'relative',
    '::before': {
        content: '""',
        display: 'inline-block',
        width: '1px',
        height: 0,
        paddingBottom: `calc(100% / (1/1))`,
    },
});

export const gridCellTextContent = css({
    width: '100%',
    [mq.medium]: {
        width: '49.5%',
        maxWidth: '650px',
    },
});

export const textCellMarkdown = css({
    textAlign: 'center',
    color: namedColours.black,
    p: gridParagraphs,
    'p:last-of-type': {
        padding: '0 !important',
    },
});

export const momentMarkdown = css({
    maxWidth: '500px',
    color: namedColours.white,
    a: {
        cursor: 'pointer',
        pointerEvents: 'all',
        color: namedColours.white,
        ':hover': {
            textDecoration: 'none',
        },
    },
    p: paragraph,
    'p:nth-of-type(2n+1)': {
        paddingRight: gutterValue('medium', 1.5),
        [mq.medium]: {
            paddingRight: 0,
        },
    },
    'p:nth-of-type(2n)': {
        paddingTop: gutterValue('xLarge', 1),
        paddingRight: 0,
        paddingBottom: gutterValue('xLarge', 1.5),
        paddingLeft: gutterValue('xLarge', 2),
    },
    'p:last-of-type': {
        paddingBottom: '0 !important',
    },
});

export const articleMarkdown = css({
    p: paragraph,
});

export const articleQuoteMarkdown = css({
    p: articleQuote,
});
