/* 
    Grid Moment
*/

// https://cubic-bezier.com/#.07,1,.37,.96
export const gridIntro = {
    type: 'tween',
    ease: [0.07, 1, 0.37, 0.96], // could be? [0, 1, 0.3, 1]
    duration: 0.5,
};

// https://cubic-bezier.com/#.83,-.65,.65,1.07
export const gridOutro = {
    type: 'tween',
    ease: [0.83, -0.65, 0.65, 1.07],
    duration: 0.6,
};

// https://cubic-bezier.com/#0,1,.3,1
export const gridMomentTextIn = {
    type: 'tween',
    ease: [0, 1, 0.3, 1],
    duration: 0.8,
};
// https://cubic-bezier.com/#.5,.5,0,1
export const gridMomentTextOut = {
    type: 'tween',
    ease: [0.5, 0.5, 0, 1],
    duration: 0.3,
};

// https://cubic-bezier.com/#.5,.5,0,1
export const gridMomentTextContentIn = {
    type: 'tween',
    ease: [0.5, 0.5, 0, 1],
    duration: 0.5,
    delay: 0.005,
};
// https://cubic-bezier.com/#.6,.3,.32,.96
export const gridMomentTextContentOut = {
    type: 'tween',
    ease: [0.6, 0.3, 0.32, 0.96],
    duration: 0.1,
    delay: 0.0,
};

/* 
    Grid Text Cell
*/

// https://cubic-bezier.com/#.07,1,.37,.96
export const gridTextCellIntro = {
    type: 'tween',
    ease: [0.07, 1, 0.37, 0.96], // could be? [0, 1, 0.3, 1]
    duration: 0.5,
    delay: 1,
};

// https://cubic-bezier.com/#.88,.37,.29,.7
export const gridTextCellOutro = {
    type: 'tween',
    ease: [0.88, 0.37, 0.29, 0.7], //[0.83, -0.65, 0.65, 1.07],
    duration: 1,
    delay: 0,
};

// https://cubic-bezier.com/#.07,1,.37,.96
export const gridTextCellContentIntro = {
    type: 'tween',
    ease: [0.5, 0.5, 0, 1],
    duration: 2,
    delay: 1.5,
};

// https://cubic-bezier.com/#.5,.5,0,1
export const gridTextCellContentOutro = {
    type: 'linear',
    duration: 0,
};

/* 
    Grid Text Cell
*/

// https://cubic-bezier.com/#.07,1,.37,.96
export const gridBlankCellIntro = {
    type: 'tween',
    ease: [0.07, 1, 0.37, 0.96], // could be? [0, 1, 0.3, 1]
    duration: 1,
    delay: 1,
};

// https://cubic-bezier.com/#.83,-.65,.65,1.07
export const gridBlankCellOutro = {
    type: 'tween',
    ease: [0.83, -0.65, 0.65, 1.07],
    duration: 1,
    delay: 1,
};

/* 
    Menu Animations
*/

// https://cubic-bezier.com/#.07,1,.37,.96
export const menuIn = {
    type: 'tween',
    ease: [0.2, 1, 0.3, 1], // could be? [0, 1, 0.3, 1]
    duration: 0.5,
};

// https://cubic-bezier.com/#.83,-.65,.65,1.07
export const menuOut = {
    type: 'tween',
    ease: [0.07, 1, 0.37, 0.96],
    duration: 0.5,
};
