import gql from 'graphql-tag';

const GET_MOMENTS = gql`
    {
        _site {
            globalSeo {
                siteName
                titleSuffix
                fallbackSeo {
                    title
                    description
                    twitterCard
                    image {
                        url
                        alt
                    }
                }
            }
        }
        home {
            id
            categoryOrder {
                name
            }
            grid {
                id
                title
                body
                category {
                    name
                }
                svg {
                    url
                    alt
                }
                theme {
                    colour {
                        hex
                    }
                }
                seoMeta {
                    title
                    description
                    image {
                        url
                        alt
                    }
                    twitterCard
                }
            }
        }
    }
`;

export default GET_MOMENTS;
