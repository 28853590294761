import React from 'react';
import { motion } from 'framer-motion';
import styled from '@emotion/styled';
import Header from 'components/organisms/header';
import Moment from 'components/organisms/moment';
const MomentPage = styled(motion.div)(
    {
        position: 'absolute',
        zIndex: 1,
        width: '100vw',
        height: 'var(--vh, 100vh)',
        perspective: '100vw',
    },
    ({ colour }) => ({
        background: colour ? `${colour}` : 'transparent',
    })
);

const MomentContainer = styled(motion.div)({
    position: 'relative',
    zIndex: 2,
    width: '100vw',
    height: 'var(--vh, 100vh)',
    overflow: 'visible',
    // border: `solid 1px red`,
});

const momementContainerVariants = {
    initial: {
        opacity: 1,
    },
    exit: (section) => {
        if (section === 'moment' || section === 'category') {
            // animate up and out, maybe we don't need to do this here on route change tho, right
            return {
                y: '-106vh',
                z: '-10vw',
                transition: {
                    ease: [0.85, 0.47, 0.1, 0.8],
                    duration: 0.9,
                    z: { delay: 0.1 },
                },
            };
        }

        return {
            opacity: 0,
            transition: {
                duration: 2,
                ease: [0.6, 0.3, 0.32, 0.96],
                opacity: {
                    delay: 1.5,
                    duration: 0.5,
                },
            },
            // transitionEnd: {
            //     display: 'none',
            // },
        };
    },
};

function MomentComponent({ allMoments, nextMoment, number, ...props }) {
    return (
        <MomentPage key={`momentPage_${number}`}>
            <Header moments={allMoments} />
            <MomentContainer
                // animate={currentMomentControls}
                initial="initial"
                exit="exit"
                variants={momementContainerVariants}
            >
                <Moment number={number} {...props} />
            </MomentContainer>
        </MomentPage>
    );
}

export default MomentComponent;
