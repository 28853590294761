import React from 'react';
import styled from '@emotion/styled';
import Button from 'components/molecules/button';
import LogoLink from 'components/molecules/header/logoLink';
import GridTextCell from 'components/molecules/cell/text';
import { gutter } from 'styles/gutter';
import { homeTitle } from 'styles/text';
import { gridCellTextContent } from 'styles/abstracts';
import { mq } from 'styles/breakpoints';

const GridContent = styled.div(gridCellTextContent, {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    position: 'relative',
    [mq.small]: {
        maxWidth: '80%',
    },
});

const Text = styled.div(
    {},
    gutter(
        { top: 0, right: 0, bottom: 0, left: 0 },
        { small: { top: 1, right: 0, bottom: 1, left: 0 } }
    )
);
const Title = styled.h1(homeTitle, {
    maxWidth: `280px`,
});

function IntroComponent({ gridArea, title, introduction, exploreLink }) {
    return (
        <GridTextCell gridArea={gridArea}>
            <GridContent>
                <LogoLink />
                <Text>
                    <Title>{title}</Title>
                </Text>
                <Button to={exploreLink}>Explore</Button>
            </GridContent>
        </GridTextCell>
    );
}

export default IntroComponent;
