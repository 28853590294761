import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import client from 'data/config/apollo-client';
import { ApplicationContextProvider } from 'data/context/application';
import { Router, useTrackedHistory } from 'data/config/router';
import { HelmetProvider } from 'react-helmet-async';
import App from 'data/containers/app';

import './index.scss';
import Tracking from 'components/organisms/tracking';

function Core() {
    const history = useTrackedHistory();
    return (
        <ApolloProvider client={client}>
            <Router history={history}>
                <HelmetProvider>
                    <App />
                    <Tracking />
                </HelmetProvider>
            </Router>
        </ApolloProvider>
    );
}

ReactDOM.render(
    <React.StrictMode>
        <ApplicationContextProvider>
            <Core />
        </ApplicationContextProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
