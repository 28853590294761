import React from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { namedColours } from 'styles/colours';
import { NavLink } from 'react-router-dom';
import ArrowRightIcon from 'components/atoms/arrowRight';
import { menuCategoryLink, menuCategoryRange } from 'styles/text';
import { gutter } from 'styles/gutter';

const ANIM_SPEED = 0.2;
const ANIM_STYLE = 'ease-out';

const CategoryLink = styled(motion.div)(
    {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    gutter({ right: 0, bottom: 0, left: 0 })
);
const CategoryNavLink = styled(NavLink)({
    textDecoration: 'none',

    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    borderBottom: `solid 1px rgba(255, 255, 255, 0.45)`,

    width: '100%',
    minWidth: '150px',
    paddingBottom: '4px',

    transition: `opacity ${ANIM_SPEED}s ${ANIM_STYLE}`,

    ':hover': {
        opacity: 0.5,
    },
});

const LinkRange = styled(motion.div)(menuCategoryRange, {
    paddingBottom: '2px',
});
const LinkText = styled(motion.div)(menuCategoryLink, {
    color: namedColours.white,
    maxWidth: '65%',
    width: '100%',
    transition: `color ${ANIM_SPEED}s ${ANIM_STYLE}`,
});
const LinkIcon = styled(ArrowRightIcon)({
    width: '11px',
    marginBottom: '5px',
    path: {
        transition: `fill ${ANIM_SPEED}s ${ANIM_STYLE}`,
    },
});

function CategoryLinkComponent({ url, range, children }) {
    return (
        <CategoryLink>
            <LinkRange>{range}</LinkRange>
            <CategoryNavLink to={url}>
                <LinkText>{children}</LinkText>
                <LinkIcon />
            </CategoryNavLink>
        </CategoryLink>
    );
}

export default CategoryLinkComponent;
