import React from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';

const MouseIcon = styled(motion.svg)({
    width: '24px',
    height: '87px',
});

const ArrowDown = styled(motion.path)({
    position: 'absolute',
    top: 0,
});

const MouseIconComponent = ({ colour = '#fff', ...props }) => {
    return (
        <MouseIcon
            viewBox="0 0 52.6 141.9"
            xmlns="http://www.w3.org/2000/svg"
            animate={{ y: [-10, 0, 3], opacity: [0, 1, 0] }}
            transition={{
                repeat: Infinity,
                repeatType: 'loop',
                duration: 2,
                repeatDelay: 0,
            }}
            {...props}
        >
            <ArrowDown
                fill={colour}
                fillOpacity=".8"
                style={{
                    y: -16,
                }}
                d="M26.3 141.9c-1.2-.9-1.5-1.4-2.6-2.4-5.3-5.3-11.3-11.4-17.4-17.6-1-1-1.8-2.1-1.5-3.6.2-1.4.4-1.7 1.7-2.2 1.4-.5 2.7-.2 3.8.8l5.1 5.1c3.5 3.5 6.9 7 10.3 10.5.8.8 1.3.8 2 0 4.9-5 9.9-10 14.8-15.1 1-1 2-1.7 3.5-1.5s1.9.3 2.4 1.7.2 2.8-.9 3.9c-2.8 2.9-4.9 5-7.8 7.9-3.1 3.1-6.4 6.4-9.6 9.4-1 1-2 2.3-3.2 3.1h-.6z"
            />
            <ArrowDown
                fill={colour}
                fillOpacity=".8"
                d="M26.3 141.9c-1.2-.9-1.5-1.4-2.6-2.4-5.3-5.3-11.3-11.4-17.4-17.6-1-1-1.8-2.1-1.5-3.6.2-1.4.4-1.7 1.7-2.2 1.4-.5 2.7-.2 3.8.8l5.1 5.1c3.5 3.5 6.9 7 10.3 10.5.8.8 1.3.8 2 0 4.9-5 9.9-10 14.8-15.1 1-1 2-1.7 3.5-1.5s1.9.3 2.4 1.7.2 2.8-.9 3.9c-2.8 2.9-4.9 5-7.8 7.9-3.1 3.1-6.4 6.4-9.6 9.4-1 1-2 2.3-3.2 3.1h-.6z"
            />
        </MouseIcon>
    );
};

export default MouseIconComponent;
