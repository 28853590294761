import React from 'react';
import styled from '@emotion/styled';
import Grid from 'components/organisms/grid';
import HomePageCTA from 'components/molecules/homePageCTA';
import ArticleLinks from 'components/organisms/articleLinks';
import Footer from 'components/organisms/footer';

const Home = styled.div({
    position: 'relative',
    width: '100vw',
    height: 'var(--vh, 100vh)',
    overflow: 'auto',
});

function HomeComponent({ articles, ctaText, ctaButtonText, ctaUrl, ...props }) {
    return (
        <Home>
            <Grid {...props} />
            <HomePageCTA
                text={ctaText}
                buttonText={ctaButtonText}
                buttonUrl={ctaUrl}
            />
            <ArticleLinks articles={articles} />
            <Footer />
        </Home>
    );
}

export default HomeComponent;
