import React from 'react';
import { useQuery } from '@apollo/client';
import GET_MOMENTS from 'data/queries/get-moments.graphql.js';
import Moment from 'components/templates/moment';
import SEO from 'utils/seo';
import slugFromString from 'utils/slugFromString';
import getMomentsFromCategories from 'utils/getMomentsFromCategories';

function getNextMoments(moments, start, count) {
    let nextMoments = [];
    let nextIndex = 0;

    for (let step = 1; step <= count; step++) {
        nextIndex = start + step;
        // If we have hit the end
        if (nextIndex === moments.length) {
            nextIndex = 0;
            // If we are past the end
        } else if (nextIndex > moments.length) {
            nextIndex = step;
        }

        if (moments[nextIndex]) {
            nextMoments.push(moments[nextIndex]);
        }
    }

    return nextMoments;
}

function getPreviousMoments(moments, start, count) {
    let previousMoments = [];
    let nextIndex = 0;

    for (let step = -1; step >= -count; step--) {
        nextIndex = start + step;

        // If we have hit the bottom
        if (nextIndex < 0) {
            nextIndex = moments.length + nextIndex;
        }

        if (moments[nextIndex]) {
            previousMoments.push(moments[nextIndex]);
        }
    }

    return previousMoments;
}

function getMoment(id, moments) {
    return moments[id * 1 - 1];
}

function getRelatedMoments(id, moments, amount) {
    let moment = null;
    let momentIndex = 0;
    let relatedMoments = [];

    if (moments.length) {
        // Get moment
        moment = getMoment(id, moments);
        // Get index
        momentIndex = moments.indexOf(moment);

        // Related moments
        relatedMoments = getNextMoments(moments, momentIndex, amount).concat(
            getPreviousMoments(moments, momentIndex, amount)
        );
    }

    return relatedMoments;
}

function MomentPageContainer({
    match: {
        url,
        params: { id },
    },
}) {
    const { loading, data } = useQuery(GET_MOMENTS);

    if (loading) {
        return false;
        // TODO: Loading page (MAKE GLOBAL FIRST)
        // return <LoaderPage />;
    }

    // TEMP whilst we dev
    const rawMoments = data ? data.home.grid : [];
    const categoryOrder = data ? data.home.categoryOrder : [];
    const moments = getMomentsFromCategories(categoryOrder, rawMoments);

    // Get different moment date
    const moment = getMoment(id, moments);
    const firstMoment = moments[0];
    const lastMoment = moments[moments.length - 1];
    const relatedMoments = getRelatedMoments(id, moments, 1);
    const nextMoment = relatedMoments[0];
    const previousMoment = relatedMoments[1];

    const momentNumber = id * 1;
    const nextNumber = momentNumber !== moments.length ? momentNumber + 1 : 1;
    const previousNumber =
        momentNumber !== 1 ? momentNumber - 1 : moments.length;
    const nextUrl = `/moment/${nextNumber}/${slugFromString(nextMoment.title)}`;
    const prevUrl = `/moment/${previousNumber}/${slugFromString(
        previousMoment.title
    )}`;

    const momentProps = {
        allMoments: moments,
        number: momentNumber,
        title: moment.title,
        body: moment.body,
        category: moment.category.name,
        colour: moment.theme.colour.hex,
        imgUrl: moment.svg.url,
        imgAlt: moment.svg.alt,
        nextColour: nextMoment
            ? nextMoment.theme.colour.hex
            : firstMoment.theme.colour.hex,
        prevColour: previousMoment
            ? previousMoment.theme.colour.hex
            : lastMoment.theme.colour.hex,
        nextNumber,
        previousNumber,
        nextUrl,
        prevUrl,
        nextMoment,
    };

    const momentSeo = {
        ...moment.seoMeta,
        backgroundColour: moment.theme.colour.hex,
        title: `${momentNumber}/50 — ${moment.seoMeta.title}`,
    };

    const seoProps = {
        pageUrl: url,
        seoDefaults: data._site.globalSeo,
        pageSeo: momentSeo,
    };

    // console.log('moment props', url, seo);
    return (
        <>
            <SEO {...seoProps} />
            <Moment {...momentProps} />
        </>
    );
}

export default MomentPageContainer;
