import { css } from '@emotion/react';
import { mq } from 'styles/breakpoints';
import { gutter } from 'styles/gutter';

/* 
    Grid
*/
export const homeTitle = css(
    {
        margin: 0,
        textAlign: 'center',
        fontSize: '22px',
        lineHeight: '26.4px',
        letterSpacing: '-0.2px',
        [mq.medium]: {
            fontSize: '60px',
            lineHeight: '72px',
        },
    },
    gutter({ top: 0, right: 0, left: 0 })
);

export const gridNumber = css({
    margin: 0,
    fontSize: '34px',
    lineHeight: '34px',
});
export const gridText = css({
    margin: 0,
    fontSize: '12px',
    lineHeight: '14px',
});

export const gridParagraphs = css(
    {
        margin: 0,
        fontSize: '12px',
        lineHeight: '19px',
        letterSpacing: '0.2px',
        [mq.medium]: {
            fontSize: '19px',
            lineHeight: '24px',
            textAlign: 'left',
            // letterSpacing: '0.2%',
        },
    },
    gutter({ top: 0, right: 0, left: 0 })
);

/* 
    Home CTA
*/
export const CTAText = css({
    margin: 0,
    fontSize: '10px',
    textAlign: 'center',
    [mq.medium]: {
        fontSize: '18px',
    },
});

/* 
    Moment 
*/
export const momentTitle = css(
    {
        margin: 0,
        fontSize: '28px',
        lineHeight: '34px',
        letterSpacing: '-0.2px',
        [mq.large]: {
            fontSize: '38px',
            lineHeight: '46px',
        },
    },
    gutter({ top: 0, right: 0, bottom: 1.5, left: 0 })
);

export const momentNumber = css({
    margin: 0,
    fontSize: '110px',
    lineHeight: '0.8',
    letterSpacing: '-5px',
    fontStyle: 'normal',
    fontWeight: 300,
    [mq.medium]: {
        fontSize: '155px',
        lineHeight: '0.75',
        letterSpacing: '-12px',
    },
});

export const momentCategory = css(
    {
        margin: 0,
        fontSize: '17px',
        lineHeight: '20px',
        letterSpacing: '-0.2px',
        fontWeight: 'normal',
        [mq.medium]: {
            fontSize: '20px',
            lineHeight: '24px',
            marginTop: '-6px',
        },
    },
    gutter(
        { top: 0, right: 0, bottom: 0.1, left: 0 },
        { small: { top: 0, right: 0, bottom: 0.25, left: 0 } }
    )
);

export const momentNavigation = css(
    {
        fontWeight: 'bold',
        fontSize: '12px',
        lineHeight: '20px',
        textTransform: 'uppercase',
        [mq.medium]: {
            fontSize: '14px',
        },
    },
    gutter()
);

export const momementCount = css(
    {
        fontSize: '12px',
        lineHeight: '14px',
        letterSpacing: '-0.2px',
        [mq.medium]: {
            fontSize: '14px',
        },
    },
    gutter()
);

export const paragraph = css(
    {
        margin: 0,
        fontSize: '12px',
        lineHeight: '19px',
        letterSpacing: '0.2px',
        [mq.medium]: {
            fontSize: '15px',
            lineHeight: '20px',
            textAlign: 'left',
            // letterSpacing: '0.2%',
        },
    },
    gutter({ top: 0, right: 0, left: 0 })
);

/* 
    Button
*/
export const button = css({
    lineHeight: 1,
    textTransform: 'uppercase',
    fontSize: '10px',
    fontWeight: 'bold',
    [mq.medium]: {
        fontSize: '12px',
    },
});

/* 
    Menu
*/
export const menuTitle = css({
    fontSize: '15px',
    lineHeight: '18px',
    fontWeight: 'bold',
    letterSpacing: '-0.2px',
});

export const menuSectionTitle = css({
    fontSize: '11px',
    lineHeight: '13px',
    fontWeight: 'normal',
    letterSpacing: '-0.2px',
    borderBottom: `solid 1px rgba(255, 255, 255, 0.45)`,
});

export const menuCategoryRange = css({
    fontSize: '11px',
    fontWeight: 300,
    letterSpacing: '1px',
    lineHeight: '15px',
});

export const menuCategoryLink = css({
    fontSize: '15px',
    lineHeight: '18px',
    fontWeight: 'bold',
    letterSpacing: '-0.2px',
});

export const menuArticleLink = css({
    fontSize: '14px',
    lineHeight: '17px',
    fontWeight: 'normal',
    letterSpacing: '-0.2px',
});

export const menuExternalLink = css({
    fontSize: '15px',
    lineHeight: '18px',
    fontWeight: 'bold',
    letterSpacing: '-0.2px',
    textDecoration: 'none',
    transition: `opacity 0.2s ease-out`,
    ':hover': {
        opacity: 0.5,
    },
});

/* 
    Article Links
*/
export const articleLinkTitle = css({
    margin: 0,
    fontSize: '11.5px',
    lineHeight: '14px',
    [mq.medium]: {
        fontSize: '22px',
        lineHeight: '26px',
    },
});

export const articleLinkShort = css({
    margin: 0,
    fontSize: '10px',
    lineHeight: '12px',
    [mq.medium]: {
        fontSize: '15px',
        lineHeight: '18px',
    },
});

/* 
    Articles
*/
export const articleTitle = css({
    margin: 0,
    fontSize: '28px',
    lineHeight: '34px',
    letterSpacing: '-0.2px',
    [mq.medium]: {
        fontSize: '50px',
        lineHeight: '60px',
    },
});

export const articleQuote = css({
    margin: 0,
    fontSize: '28px',
    lineHeight: '34px',
    letterSpacing: '-0.2px',
    fontWeight: 'bold',
    [mq.medium]: {
        fontSize: '52px',
        lineHeight: '62px',
    },
});
