import React from 'react';
import styled from '@emotion/styled';
import Hero from 'components/molecules/article/hero';
import SectionOne from 'components/molecules/article/sectionOne';
import SectionTwo from 'components/molecules/article/sectionTwo';
import SectionThree from 'components/molecules/article/sectionThree';
import Outro from 'components/molecules/article/outro';
import Header from 'components/organisms/header';
import Footer from 'components/organisms/footer';
import { motion } from 'framer-motion';

const Article = styled(motion.div)({
    position: 'relative',
    width: '100vw',
    height: 'var(--vh, 100vh)',
    overflow: 'auto',
});

const Body = styled.div({});

function ArticleComponent({
    title,
    introduction,
    heroIllustration: { alt: heroImageAlt, url: heroImageUrl },
    theme: {
        colour: { hex: colour },
    },
    sectionOneImage: { alt: sectionOneImagaAlt, url: sectionOneImageUrl },
    sectionOneText,
    sectionTwoQuote,
    sectionTwoText,
    sectionThreeImage: { alt: sectionThreeImagaAlt, url: sectionThreeImageUrl },
    sectionThreeText,
    outroText,
}) {
    return (
        <Article>
            <Header background={colour} />
            <Hero
                title={title}
                introduction={introduction}
                colour={colour}
                heroImageUrl={heroImageUrl}
                heroImageAlt={heroImageAlt}
            />
            <Body>
                <SectionOne
                    imageUrl={sectionOneImageUrl}
                    imageAlt={sectionOneImagaAlt}
                    text={sectionOneText}
                />
                <SectionTwo quote={sectionTwoQuote} text={sectionTwoText} />
                <SectionThree
                    imageUrl={sectionThreeImageUrl}
                    imageAlt={sectionThreeImagaAlt}
                    text={sectionThreeText}
                />
                <Outro text={outroText} />
            </Body>
            <Footer />
        </Article>
    );
}

export default ArticleComponent;
