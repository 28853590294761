import React from 'react';
import styled from '@emotion/styled';
import { gutter } from 'styles/gutter';
import { namedColours } from 'styles/colours';
import { button } from 'styles/text';
import { motion, useAnimation } from 'framer-motion';
import UniversalLink from 'components/atoms/universalLink';

// -------------------------
// Style
// -------------------------
const Button = styled(motion.div)({
    display: 'block',
    position: 'relative',
    perspective: '100vw',
    color: namedColours.white,
});

const Background = styled(motion.div)({
    position: 'absolute',
    zIndex: 1,
    top: 0,
    width: '100%',
    height: '100%',
    background: namedColours.gold,
    willChange: 'transform',
});

const Text = styled(motion.div)(
    button,
    {
        position: 'relative',
        zIndex: 2,
        textDecoration: 'none',
        color: namedColours.white,
        paddingBottom: `2px`,
    },
    gutter(
        { top: 0.3, right: 1.5, bottom: 0.3, left: 1.5 },
        {
            small: { top: 0.5, right: 2, bottom: 0.5, left: 2 },
            large: { top: 0.3, right: 1, bottom: 0.3, left: 1 },
        }
    )
);

// -------------------------
// Animation
// -------------------------
const buttonVariants = {
    initial: {},
    hover: {},
    hoverEnd: {},
};

const backgroundVariants = {
    initial: {
        backgroundColor: namedColours.gold,
        scale: 1,
    },
    hover: {
        backgroundColor: namedColours.black,
        scale: 1.15,
        transition: {
            ease: [0.5, 0.5, 0, 1],
            duration: 0.5,
        },
    },
    hoverEnd: {
        backgroundColor: namedColours.gold,
        scale: 1,
        transition: {
            ease: [0.07, 1, 0.37, 0.96],
            duration: 0.6,
        },
    },
};

const textVariants = {
    initial: {},
    hover: {},
};

// -------------------------
// Component
// -------------------------
function ButtonComponent({ children, ...props }) {
    const buttonAnim = useAnimation();

    return (
        <UniversalLink {...props}>
            <Button
                initial="initial"
                animate={buttonAnim}
                onHoverStart={() => buttonAnim.start('hover')}
                onHoverEnd={() => buttonAnim.start('hoverEnd')}
                variants={buttonVariants}
            >
                <Text variants={textVariants}>{children}</Text>
                <Background variants={backgroundVariants} />
            </Button>
        </UniversalLink>
    );
}

export default ButtonComponent;
