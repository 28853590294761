import React from 'react';
import { motion } from 'framer-motion';
import styled from '@emotion/styled';
import GridMoment from 'components/organisms/grid/moment';
import GridBlank from 'components/molecules/cell/blank';
import GridMenuLink from 'components/organisms/grid/menuLink';
import GridIntro from 'components/organisms/grid/intro';
import GridAbout from 'components/organisms/grid/about';
import GridExplore from 'components/organisms/grid/explore';
import { namedColours } from 'styles/colours';
import { mq } from 'styles/breakpoints';
import { GRID_BLANKS } from 'styles/constants';

const Grid = styled(motion.div)({
    width: '100%',
    zIndex: 2,
    display: 'grid',
    overflow: 'hidden',
    gap: '0px 0px',
    gridTemplateColumns: `1fr 1fr 1fr 1fr 1fr 1fr`,
    gridTemplateAreas: `
        ". . . . . MenuLink"
        "Intro Intro Intro Intro . Blank-1"
        "Intro Intro Intro Intro . ."
        "Intro Intro Intro Intro Highlight-1 Highlight-1"
        "Intro Intro Intro Intro Highlight-1 Highlight-1"
        "Intro Intro Intro Intro . ."
        "Intro Intro Intro Intro . ."
        ". . . . . Blank-2"
        "Blank-3 About About About About ."
        ". About About About About ."
        ". About About About About ."
        ". About About About About ."
        ". About About About About ."
        "Blank-4 About About About About ."
        ". . Highlight-2 Highlight-2 Blank-5 ."
        ". . Highlight-2 Highlight-2 . ."
        ". . Explore Explore Explore Explore"
        "Blank-6 . Explore Explore Explore Explore"
        ". . Explore Explore Explore Explore"
        "Highlight-3 Highlight-3 Explore Explore Explore Explore"
        "Highlight-3 Highlight-3 Explore Explore Explore Explore"
        ". Blank-14 Explore Explore Explore Explore"
        "Blank-7 . . . Highlight-4 Highlight-4"
        ". . . . Highlight-4 Highlight-4"
    `,
    [mq.large]: {
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
        gridTemplateAreas: `
        ". . . . . Highlight-1 Highlight-1 Blank-1 MenuLink"
        ". Intro Intro Intro Intro Highlight-1 Highlight-1 . ."
        "Blank-2 Intro Intro Intro Intro . . Highlight-2 Highlight-2"
        ". Intro Intro Intro Intro . . Highlight-2 Highlight-2"
        ". Intro Intro Intro Intro Blank-3 . . ."
        ". Intro Intro Intro Intro . . . ."
        ". . Highlight-3 Highlight-3 About About About About ."
        "Blank-4 . Highlight-3 Highlight-3 About About About About ."
        ". Highlight-4 Highlight-4 . About About About About ."
        "Blank-5 Highlight-4 Highlight-4 Blank-6 About About About About Blank-7"
        ". Highlight-5 Highlight-5 . . . . . Blank-8"
        "Blank-9 Highlight-5 Highlight-5 . . . Blank-10 . Blank-11"
        ". Explore Explore Explore Explore . . Blank-12 Blank-11"
        ". Explore Explore Explore Explore . Blank-13 Blank-12 Blank-11"
        ". Explore Explore Explore Explore . Blank-13 Blank-12 Blank-11"
        "Blank-14 Explore Explore Explore Explore . . . Blank-11"
        ". . Blank-15 . . . . . ."
        "Blank-16 Highlight-6 Highlight-6 Blank-17 . . Blank-18 . ."
        ". Highlight-6 Highlight-6 Highlight-7 Highlight-7 . Blank-19 Blank-19 Blank-19"
        "Blank-20 Blank-20 Blank-21 Highlight-7 Highlight-7 Blank-23 Blank-19 Blank-19 Blank-19"
        "Blank-20 Blank-20 Blank-22 . . Blank-23 Blank-19 Blank-19 Blank-19"
        "Blank-20 Blank-20 Blank-22 Blank-24 Blank-25 Blank-23 Blank-19 Blank-19 Blank-19"

    `,
    },
});

//        "Blank-20 Blank-20 Blank-22 Blank-24 Blank-24 Blank-23 Blank-19 Blank-19 Blank-19"

const gridVariants = {};

function GridComponent({
    moments,
    title,
    introduction,
    about,
    explore,
    exploreLink,
}) {
    return (
        <Grid
            initial="initial"
            animate="visible"
            exit="exit"
            variants={gridVariants}
        >
            {moments.map(
                (
                    {
                        number,
                        title,
                        svg: { url: imgUrl, alt: imgAlt },
                        theme: {
                            colour: { hex: colour },
                        },
                        category: { name: categoryName },
                    },
                    i
                ) => {
                    let gridArea = 'auto';
                    if (i === 5) {
                        gridArea = 'Highlight-1';
                    }
                    if (i === 16) {
                        gridArea = 'Highlight-2';
                    }
                    if (i === 26) {
                        gridArea = 'Highlight-3';
                    }
                    if (i === 32) {
                        gridArea = 'Highlight-4';
                    }
                    if (i === 35) {
                        gridArea = 'Highlight-5';
                    }
                    if (i === 63) {
                        gridArea = 'Highlight-6';
                    }
                    if (i === 69) {
                        gridArea = 'Highlight-7';
                    }
                    return (
                        <GridMoment
                            key={i}
                            title={title}
                            number={number}
                            gridNumber={i + 1}
                            colour={colour}
                            category={categoryName}
                            imgUrl={imgUrl}
                            imgAlt={imgAlt}
                            gridArea={gridArea}
                        />
                    );
                }
            )}
            {GRID_BLANKS.map(
                ({ colour, large: { colour: colourDesktop } }, i) => {
                    const colourVal =
                        colour === 'white'
                            ? `rgba(0,0,0,0)`
                            : namedColours[colour];
                    const colourValDesktop =
                        colourDesktop === 'white'
                            ? `rgba(0,0,0,0)`
                            : namedColours[colourDesktop];
                    return (
                        <GridBlank
                            key={i}
                            colour={colourVal}
                            colourDesktop={colourValDesktop}
                            gridArea={`Blank-${i + 1}`}
                        />
                    );
                }
            )}
            <GridIntro
                gridArea={`Intro`}
                title={title}
                introduction={introduction}
                exploreLink={exploreLink}
            />
            <GridAbout gridArea={`About`} about={about} />
            <GridExplore gridArea={`Explore`} explore={explore} />
            <GridMenuLink />
        </Grid>
    );
}

export default GridComponent;
