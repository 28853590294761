import React, { useLayoutEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useApplicationDispatch } from 'data/context/application';
import isTouchDevice from 'utils/isTouchDevice';
import App from 'components/templates/App';
import { useQuery } from '@apollo/client';
import getMomentsFromCategories from 'utils/getMomentsFromCategories';
import GET_HOME from 'data/queries/get-home.graphql';

function getViewportHeight() {
    const rVH = window.innerHeight;
    return rVH;
}
function getViewportWidth() {
    const rVW = window.innerWidth;
    return rVW;
}

// Potentially split this into two if you make any data calls here so they are not linked to each others event updates
function AppUIContainer() {
    const dispatch = useApplicationDispatch();
    const debouncedResize = useDebouncedCallback(() => {
        onResize();
    }, 100);

    function onResize() {
        document.documentElement.style.setProperty(
            '--vh',
            `${getViewportHeight()}px`
        );
        document.documentElement.style.setProperty(
            '--vw',
            `${getViewportWidth()}px`
        );
    }

    useLayoutEffect(() => {
        onResize();
        window.addEventListener('resize', debouncedResize);
        return () => {
            window.removeEventListener('resize', debouncedResize);
        };
    });

    useLayoutEffect(() => {
        console.log('hasTouch', isTouchDevice());
        dispatch({
            type: 'HAS_TOUCH',
            payload: { hasTouch: isTouchDevice() },
        });
    });

    return <AppDataContainer />;
}

// Attach number property to moment
// Attach additional moments for grid design
function makeGridMoments(moments) {
    const momentsWithNumber = moments.map((moment, i) => {
        return {
            ...moment,
            number: i + 1,
        };
    });
    const moreMoments = [
        ...momentsWithNumber,
        ...momentsWithNumber.slice(0, 23),
    ];
    return moreMoments;
}

function shuffleArrayItems(array) {
    let counter = array.length;
    while (counter > 0) {
        const index = Math.floor(Math.random() * counter);
        counter--;
        const temp = array[counter];
        array[counter] = array[index];
        array[index] = temp;
    }
    return array;
}

function AppDataContainer() {
    const { loading, data } = useQuery(GET_HOME);

    if (loading) {
        return false;
        // TODO: Loading page (MAKE GLOBAL FIRST)
        // return <LoaderPage />;
    }

    const rawMoments = data ? data.home.grid : [];
    const categoryOrder = data ? data.home.categoryOrder : [];

    const orderedMoments = getMomentsFromCategories(categoryOrder, rawMoments);
    const moments = makeGridMoments(orderedMoments);
    const gridMoments = shuffleArrayItems(moments);
    const appData = {
        gridMoments,
    };

    return (
        <>
            <App {...appData} />
        </>
    );
}

export default AppUIContainer;
