import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { getEnv } from 'env.js';
import { createOptionsString } from 'utils/images';

const APP_URL = getEnv('BASE_URL');
const SITE_NAME = 'Site name';

const DEFAULT_SEO = {
    schema: 'website',
    url: APP_URL,
    siteName: SITE_NAME,
    titleSuffix: `:: ${SITE_NAME}`,
    title: 'Page title',
    description: 'Page description',
    twitterCard: 'summary', // 'summary', 'summary_large_image'
    image: false,
    backgroundColour: '#350F4F',
    imagePortrait: false,
};

// Merge SEO settings, DatoCMS schema
function mergeSeo(defaultSeo, globalSeo, pageSeo, pageUrl) {
    const normalisedGlobalSeo = {
        siteName: globalSeo.siteName,
        ...(globalSeo.titleSuffix && {
            titleSuffix: globalSeo.titleSuffix,
        }),
        ...(globalSeo.fallbackSeo.title && {
            title: globalSeo.fallbackSeo.title,
        }),
        ...(globalSeo.fallbackSeo.description && {
            description: globalSeo.fallbackSeo.description,
        }),
        ...(globalSeo.fallbackSeo.image && {
            image: globalSeo.fallbackSeo.image.url,
        }),
        ...(globalSeo.fallbackSeo.twitterCard && {
            twitterCard: globalSeo.fallbackSeo.twitterCard,
        }),
    };

    const normalisedPageSeo = {
        ...(pageSeo && {
            ...(pageSeo.title && {
                title: pageSeo.title,
            }),
            ...(pageSeo.description && {
                description: pageSeo.description,
            }),
            ...(pageSeo.image && {
                image: pageSeo.image.url,
            }),
            ...(pageSeo.backgroundColour && {
                backgroundColour: pageSeo.backgroundColour,
            }),
            ...(pageSeo.twitterCard && {
                twitterCard: pageSeo.twitterCard,
            }),
        }),
    };

    const mergedSeo = {
        ...defaultSeo,
        ...normalisedGlobalSeo,
        ...normalisedPageSeo,
        ...(pageUrl && { url: `${APP_URL}${pageUrl}` }),
    };

    return mergedSeo;
}

// Prepare img url for specific unfurl services
const seoImageURL = (imgUrl, backgroundColour, isPortrait) => {
    // split colour for use in url params
    const backgroundColourArray = backgroundColour.split('#')[1];
    // strip https:// so we can serve up http for twitter
    let urlWithNoProtocol = `${imgUrl.split('//')[1]}`;
    const landscapeOptions = {
        fm: 'jpg',
        w: '1200',
        h: '628',
        q: '69',
        fit: 'fill',
        fill: 'solid',
        'fill-color': backgroundColourArray,
    };
    const portraitOptions = {
        fm: 'jpg',
        w: '1080',
        h: '1350',
        q: '69',
    };
    // append options string
    if (isPortrait) {
        urlWithNoProtocol += createOptionsString(portraitOptions);
    } else {
        urlWithNoProtocol += createOptionsString(landscapeOptions);
    }

    return urlWithNoProtocol;
};

const getMetaTags = ({
    siteName,
    title,
    url,
    description,
    twitterCard,
    image,
    backgroundColour,
    imagePortrait,
}) => {
    const metaTags = [
        { itemprop: 'name', content: title },
        { itemprop: 'description', content: description },
        { name: 'twitter:card', content: twitterCard },
        { name: 'twitter:title', content: title },
        { name: 'twitter:description', content: description },
        { property: 'og:type', content: 'website' },
        { property: 'og:site_name', content: siteName },
        { property: 'og:url', content: url },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
    ];

    if (image) {
        metaTags.push(
            {
                itemprop: 'image',
                content: `https://${seoImageURL(
                    image,
                    backgroundColour,
                    imagePortrait
                )}`,
            },
            // Twitter cards seem to work when the image is http, possibly something to do with same site/domain content?
            {
                name: 'twitter:image:src',
                content: `http://${seoImageURL(
                    image,
                    backgroundColour,
                    imagePortrait
                )}`,
            },
            {
                property: 'og:image',
                content: `https://${seoImageURL(
                    image,
                    backgroundColour,
                    imagePortrait
                )}`,
            }
        );
    }

    return metaTags;
};

// Maybe setup some defaults here that are obvious to be configured?
const SEO = ({ seoDefaults, pageSeo, pageUrl }) => {
    const seoOptions = mergeSeo(DEFAULT_SEO, seoDefaults, pageSeo, pageUrl);
    const seoType = `http://schema.org/${seoOptions.schema}`;
    const title = `${seoOptions.title} ${seoOptions.titleSuffix}`;

    useEffect(() => {
        window.prerenderReady = true;
    });

    return (
        <Helmet
            htmlAttributes={{
                lang: 'en',
                itemscope: undefined,
                itemtype: seoType,
            }}
            title={title}
            link={[
                {
                    rel: 'canonical',
                    href: seoOptions.url,
                },
            ]}
            meta={getMetaTags({
                ...seoOptions,
                title,
            })}
        />
    );
};

export default SEO;
