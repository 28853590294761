import create from 'zustand';
import { persist } from 'zustand/middleware';
import { createTrackedSelector } from 'react-tracked';

const analyticsStore = create(
    persist(
        (set, get) => ({
            analytics: true,
            preferenceSaved: false,
            setAnalytics: (analytics) => {
                set({
                    analytics,
                    preferenceSaved: true,
                });
            },
        }),
        {
            name: 'analytics',
        }
    )
);

export const useAnalyticsStore = createTrackedSelector(analyticsStore);
