import React from 'react';
import styled from '@emotion/styled';
// import { motion } from 'framer-motion';

const ExternalIcon = styled.svg({});

const ExternalIconComponent = ({ colour = '#fff', ...props }) => (
    <ExternalIcon
        viewBox="0 0 19 19"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path fill={colour} d="M14.75 5C14.75 4.58579 14.4142 4.25 14 4.25L7.25 4.25C6.83579 4.25 6.5 4.58579 6.5 5C6.5 5.41421 6.83579 5.75 7.25 5.75H13.25V11.75C13.25 12.1642 13.5858 12.5 14 12.5C14.4142 12.5 14.75 12.1642 14.75 11.75L14.75 5ZM4.53033 15.5303L14.5303 5.53033L13.4697 4.46967L3.46967 14.4697L4.53033 15.5303Z"/>
    </ExternalIcon>
);

export default ExternalIconComponent;
