import React from 'react';
import { motion } from 'framer-motion';
import styled from '@emotion/styled';
import { aspectRatioCell } from 'styles/abstracts';
// import randomNumber from 'utils/randomNumber';
import { gridBlankCellIntro, gridBlankCellOutro } from 'styles/animation';
import { mq, mqr } from 'styles/breakpoints';

const GridBlank = styled(motion.div)(
    {
        ...aspectRatioCell,
        width: '100%',
        height: '100%',
        perspective: '100vw',
    },
    ({ gridArea, colour, colourDesktop }) => ({
        gridArea: gridArea,
        // It seems it's important to have zIndex specified after gridArea
        // I am not sure if this is bcs of framer motion or some random CSS spec
        zIndex: 1,
        // Need to display none for mobile so we don't have blanks breaking the grid
        // Maybe therre is a nicer way of doing this
        [mqr.large]: {
            display: colour ? 'block' : 'none !important',
        },
        [mq.large]: {
            display: colourDesktop ? 'block' : 'none !important',
        },
    })
);

const Background = styled(motion.div)(({ colour, colourDesktop }) => ({
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%', // Workaround for sub-pixel rendering
    background: colour ? `${colour}` : 'transparent',
    [mq.large]: {
        background: colourDesktop ? `${colourDesktop}` : 'transparent',
    },
}));

const backgroundVariants = {
    initial: { scale: 0 },
    visible: {
        scale: 1,
        // display: 'block',
        transition: gridBlankCellIntro,
    },
    exit: {
        scale: 0,
        transition: gridBlankCellOutro,
        // transitionEnd: {
        //     display: 'none',
        // },
    },
};

function GridBlankComponent({ gridArea, colour, colourDesktop }) {
    return (
        <GridBlank
            gridArea={gridArea}
            colour={colour}
            colourDesktop={colourDesktop}
        >
            <Background
                colour={colour}
                colourDesktop={colourDesktop}
                initial="initial"
                animate="visible"
                exit="exit"
                variants={backgroundVariants}
            />
        </GridBlank>
    );
}

export default GridBlankComponent;
