import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { NavLink, useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import { aspectRatioCell } from 'styles/abstracts';
import randomNumber from 'utils/randomNumber';

import { gridIntro, gridOutro } from 'styles/animation';
import { mq } from 'styles/breakpoints';
import { useInView } from 'react-intersection-observer';
import slugFromString from 'utils/slugFromString';

function displayMoment(number, gridArea) {
    if (
        number >= 54 ||
        gridArea === 'Highlight-5' ||
        gridArea === 'Highlight-6' ||
        gridArea === 'Highlight-7'
    ) {
        return false;
    }
    return true;
}

const GridMoment = styled(motion.div)(
    {
        ...aspectRatioCell,
        width: '100%',
        height: '100%',
        perspective: '100vw',
        overflow: 'hidden',
    },
    ({ gridArea, number }) => ({
        gridArea: gridArea,
        // It seems it's important to have zIndex specified after gridArea
        // I am not sure if this is bcs of framer motion or some random CSS spec
        zIndex: 1,
        display: displayMoment(number, gridArea) ? 'block' : 'none',
        [mq.large]: {
            display: 'block',
        },
    })
);

const GridContent = styled(motion.div)(
    {
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '100%',
    },
    ({ colour = 'hotpink', originX = 'left', originY = 'top' }) => ({
        background: colour,
        transformOrigin: `${originX} ${originY}`,
    })
);

const gridContentMotion = {
    initial: { scale: 0, display: 'none' },
    visible: (delay) => ({
        scale: 1,
        display: 'block',
        transition: {
            ...gridIntro,
            delay,
        },
    }),
    exit: (delay) => ({
        scale: 0,
        transition: {
            ...gridOutro,
            delay,
        },
        transitionEnd: {
            display: 'none',
        },
    }),
};

const GridLink = styled(NavLink)({
    position: 'absolute',
    top: 0,
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    width: '100%',
    height: '100%',
    textDecoration: 'none',
});

const GridBox = styled(motion.div)({
    width: '100%',
    height: '100%',
    perspective: '100vw',
    perspectiveOrigin: 'center',
});

const Image = styled(motion.img)({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    willChange: 'transform',
});

const imageMotion = {
    inition: {
        z: 0,
    },
    hover: {
        z: -500,
        transition: {
            type: 'spring',
            stiffness: 170,
            damping: 26,
            duration: 4,
        },
    },
    hoverEnd: {
        z: 0,
        transition: {
            type: 'spring',
            stiffness: 170,
            damping: 26,
            duration: 4,
        },
    },
};

function GridMomentComponent({
    gridNumber,
    gridArea,
    number,
    colour,
    imgUrl,
    imgAlt,
    title,
}) {
    const {
        location: { pathname },
    } = useHistory();
    const gridContentControls = useAnimation();
    const gridBoxControls = useAnimation();
    const [inViewRef, inView] = useInView({
        triggerOnce: true,
    });

    // Randomise Intro animation
    const originX = 'center'; //coinFlip() ? 'left' : 'right';
    const originY = 'center'; //coinFlip() ? 'top' : 'bottom';
    const delay = randomNumber(0, 1.2);

    useEffect(() => {
        if (pathname !== '/') {
            return false;
        }
        if (inView) {
            gridContentControls.start('visible');
        } else {
            gridContentControls.start('exit');
        }
    }, [gridContentControls, inView, pathname]);

    return (
        <GridMoment ref={inViewRef} number={gridNumber} gridArea={gridArea}>
            <GridContent
                initial="initial"
                exit="exit"
                originX={originX}
                originY={originY}
                colour={colour}
                variants={gridContentMotion}
                custom={delay}
                animate={gridContentControls}
            >
                <GridLink to={`/moment/${number}/${slugFromString(title)}`}>
                    <GridBox
                        initial="initial"
                        animate={gridBoxControls}
                        onHoverStart={() => gridBoxControls.start('hover')}
                        onHoverEnd={() => gridBoxControls.start('hoverEnd')}
                    >
                        <Image
                            src={imgUrl}
                            alt={imgAlt}
                            variants={imageMotion}
                        />
                    </GridBox>
                </GridLink>
            </GridContent>
        </GridMoment>
    );
}

export default GridMomentComponent;
