import React from 'react';
import styled from '@emotion/styled';
import ReactMarkdown from 'react-markdown';
import { mq } from 'styles/breakpoints';
import { gutter, gutterValue } from 'styles/gutter';
import { articleMarkdown, articleQuoteMarkdown } from 'styles/abstracts';

const SectionTwo = styled.div(
    {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
    },
    gutter()
);

const QuoteContainer = styled.div(
    gutter({ left: .25, right: 0, bottom: 0, top: 0 }, {large: { left: 0 }}),
    {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        paddingRight: '25%',
        [mq.large]: {
            paddingRight: '50%',
        },
    },
);

const Quote = styled(ReactMarkdown)(
    articleQuoteMarkdown,
    {
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        // background: 'blue',
        maxWidth: '550px',
        marginTop: `0px`,
        [mq.large]: {
            marginTop: `-${gutterValue('xLarge', 2.5)}`,
        },
    },
    gutter({ left: 0 })
);

const Text = styled(ReactMarkdown)(
    articleMarkdown,
    {
        order: 2,
        [mq.large]: {
            order: 1,
            maxWidth: '670px',
        },
    },
    gutter({ left: .25, right: 2.2 }, {large: { top: 1.5, bottom: 1 }})
);

function SectionTwoComponent({ quote, text }) {
    return (
        <SectionTwo>
            <QuoteContainer>
                <Quote>{`“${quote}”`}</Quote>
            </QuoteContainer>
            <Text>{text}</Text>
        </SectionTwo>
    );
}

export default SectionTwoComponent;
