import React from 'react';
import WGCLogo from 'components/atoms/logo';

const FooterComponent = () => (
    <div className="gc-footer-component" >
        <div className="gc-footer-top gc-component-grid-container">
            <div className="gc-footer-logo">
                <a rel="noopener noreferrer" href="https://gold.org" target="_blank"><WGCLogo width="120px" colour="white"/></a>
            </div>
            <div className="gc-footer-line">
                <div className="gc-footer-line-element"></div>
            </div>
        </div>

        <div className="gc-footer-bottom gc-component-grid-container">
            <div className="gc-footer-copyright-cell">
                <span className="gc-footer-copyright" >© 2021 World Gold Council</span>
            </div>
            <div className="gc-footer-menu-items">
                <div className="gc-footer-menu-items-wrapper">
                    <a rel="noopener noreferrer" href="https://www.gold.org/cookies" target="_blank"
                        className="gc-footer-menu-item">Cookies</a>
                    <a rel="noopener noreferrer" href="https://www.gold.org/terms-and-conditions" target="_blank"
                        className="gc-footer-menu-item">Terms &amp; Conditions</a>
                    <a rel="noopener noreferrer" href="https://www.gold.org/privacy" target="_blank"
                        className="gc-footer-menu-item">Privacy Policy</a>
                </div>
            </div>
        </div>
    </div>
);

export default FooterComponent;
