export const DRAG_THESHOLD_SCROLL = -75;
export const DRAG_THESHOLD_TOUCH = -180;

export const GRID_BLANKS = [
    {
        colour: 'white',
        large: {
            colour: 'white',
        },
    },
    {
        colour: 'white',
        large: {
            colour: 'gold',
        },
    },
    {
        colour: 'gold',
        large: {
            colour: 'gold',
        },
    },
    {
        colour: 'purple',
        large: {
            colour: 'white',
        },
    },
    {
        colour: 'gold',
        large: {
            colour: 'gold',
        },
    },
    {
        colour: 'darkPurple',
        large: {
            colour: 'purple',
        },
    },
    {
        colour: 'white',
        large: {
            colour: 'gold',
        },
    },
    {
        large: {
            colour: 'darkPurple',
        },
    },
    {
        large: {
            colour: 'white',
        },
    },
    {
        large: {
            colour: 'white',
        },
    },
    {
        large: {
            colour: 'white',
        },
    },
    {
        large: {
            colour: 'white',
        },
    },
    {
        large: {
            colour: 'white',
        },
    },
    {
        large: {
            colour: 'purple',
        },
    },
    {
        large: {
            colour: 'darkGreen',
        },
    },
    {
        large: {
            colour: 'white',
        },
    },
    {
        large: {
            colour: 'white',
        },
    },
    {
        large: {
            colour: 'white',
        },
    },
    {
        large: {
            colour: 'white',
        },
    },
    {
        large: {
            colour: 'white',
        },
    },
    {
        large: {
            colour: 'gold',
        },
    },
    {
        large: {
            colour: 'white',
        },
    },
    {
        large: {
            colour: 'white',
        },
    },
    {
        large: {
            colour: 'white',
        },
    },
    {
        large: {
            colour: 'white',
        },
    },
];
