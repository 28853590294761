import 'whatwg-fetch';
import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { getEnv } from 'env.js';

const API_URL = getEnv('API_URL');
const API_TOKEN = getEnv('API_TOKEN');

const link = new HttpLink({
    uri: API_URL,
    headers: {
        Authorization: `Bearer ${API_TOKEN}`,
    },
});

const client = new ApolloClient({
    link,
    cache: new InMemoryCache(),
});

export default client;
