import React from 'react';
import { motion, useTransform } from 'framer-motion';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { namedColours } from 'styles/colours';
import { mq } from 'styles/breakpoints';
import { momementCount, momentNavigation } from 'styles/text';
import { NavLink } from 'react-router-dom';

const link = css(momentNavigation, {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    textDecoration: 'none',
    [mq.large]: {
        width: '40%',
    },
});

const Navigation = styled(motion.div)({
    position: 'absolute',
    zIndex: 4,
    bottom: '0',
    width: '100%',
    willChange: 'transform',
    perspective: '100vw',
    pointerEvents: 'none',
});

const Background = styled(motion.div)({
    position: 'absolute',
    zIndex: 1,
    width: '100%',
    height: '100%',
    bottom: 0,
    background: namedColours.white,
});

const backgroundVariants = {
    hidden: {
        y: 100,
        display: 'block',
        transition: { duration: 0.3, ease: 'easeIn' },
    },
    visible: {
        y: 0,

        transition: { duration: 0.45, ease: 'easeOut' },
    },
    exit: {
        display: 'none',
        transition: {
            duration: 0,
        },
    },
};

const Content = styled(motion.div)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    zIndex: 2,
    willChange: 'transform',
    pointerEvents: 'all',
});

const contentVariants = {
    hidden: {
        opacity: 0,
        y: 100,
        transition: { duration: 0 },
    },
    visible: {
        opacity: 1,
        y: 0,
        // transition: { duration: 0.45, ease: 'easeOut' },
    },
    exit: {
        opacity: 0,
        transition: {
            duration: 0,
        },
    },
};

const Previous = styled(motion.div)({
    [mq.large]: {
        justifyContent: 'flex-end',
    },
});
const Next = styled(motion.div)({
    [mq.large]: {
        justifyContent: 'flex-start',
    },
});
const MomentLink = styled(NavLink)(link, {
    display: 'block',
    color: 'inherit',
});
const Count = styled(motion.div)(momementCount, {
    textAlign: 'center',
    width: '30%',
    [mq.large]: {},
});

function NavigationComponent({
    number,
    nextUrl,
    prevUrl,
    colour,
    controls,
    dragPosY,
}) {
    const opacity = useTransform(dragPosY, [0, 0, -50], [1, 1, 0]);
    const color = useTransform(dragPosY, [0, 0, -50], [colour, colour, `#fff`]);

    return (
        <Navigation
            initial="hidden"
            animate={controls}
            style={{ color: colour }}
        >
            <Background
                initial="hidden"
                exit="exit"
                variants={backgroundVariants}
                animate={controls}
                style={{
                    opacity,
                }}
            />
            <Content
                initial="hidden"
                exit="exit"
                variants={contentVariants}
                animate={controls}
                style={{ color }}
            >
                <Previous>
                    <MomentLink to={prevUrl}>Previous</MomentLink>
                </Previous>
                <Count>{number}/50</Count>
                <Next>
                    <MomentLink to={nextUrl}>Next</MomentLink>
                </Next>
            </Content>
        </Navigation>
    );
}

export default NavigationComponent;
