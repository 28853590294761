import { useReducer } from 'react';
import { createContainer } from 'react-tracked';

const initialState = {
    menu: false,
    hasTouch: true,
    navigationHistory: []
    // menuIconVisible: true,
};
const reducer = (state, action) => {
    switch (action.type) {
        case 'MENU_OPEN':
            return { ...state, menu: true };
        case 'MENU_CLOSE':
            return { ...state, menu: false };
        case 'MENU_TOGGLE':
            return { ...state, menu: !state.menu };
        case 'HAS_TOUCH':
            return { ...state, hasTouch: action.payload.hasTouch };
        // case 'MENU_ICON_IN_VIEW':
        //     return { ...state, menuIconVisible: true };
        // case 'MENU_ICON_OUT_VIEW':
        //     return { ...state, menuIconVisible: false };
        // case 'LOCATION_CHANGE':
        //     return { ...state, menu: false };
        case 'HISTORY_PUSH':
          return {
            ...state,
            navigationHistory: [...state.navigationHistory, action.data ]
          };
        default:
            return state;
    }
};

export const {
    Provider: ApplicationContextProvider,
    useSelector: useApplicationSelector,
    useTracked: useApplicationTracked,
    useTrackedState: useApplicationState,
    useUpdate: useApplicationDispatch,
} = createContainer(() => useReducer(reducer, initialState));
