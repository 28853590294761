import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import GET_MENU from 'data/queries/get-home.graphql.js';
import Menu from 'components/organisms/menu';
import usePrevious from 'utils/usePrevious';
import { useHistory } from 'react-router-dom';
import { namedColours } from 'styles/colours';
import getMomentsFromCategories from 'utils/getMomentsFromCategories';
import slugFromString from 'utils/slugFromString';

const offsetColours = {
    '#1B1D1F': namedColours.gold,
    '#0F4430': namedColours.green,
    '#350F4F': namedColours.purple,
    '#BA1126': namedColours.darkRed,
    '#A39161': namedColours.black,
    '#7A3271': namedColours.darkPurple,
};

function getCounterColour(colour) {
    const offsetColour = offsetColours[colour]
        ? offsetColours[colour]
        : namedColours.gold;
    return offsetColour;
}

function padNumber(num) {
    return num < 10 ? `0${num}` : num;
}

// Build array of category objects for use in menu component
function buildCategories(categories, moments) {
    let lastCategoryRangeEnd = 1;
    return categories.map((category) => {
        // get all moments for category
        const momentsInCategory = moments.filter(
            (moment) => moment.category.name === category.name
        );

        const rangeStart = lastCategoryRangeEnd;
        const rangeEnd = lastCategoryRangeEnd + momentsInCategory.length - 1;
        const categoryRange = `${padNumber(rangeStart)}–${padNumber(rangeEnd)}`;

        lastCategoryRangeEnd = rangeEnd + 1;
        return {
            name: category.name,
            url: `/moment/${rangeStart}/${slugFromString(
                momentsInCategory[0].title
            )}`,
            range: categoryRange,
        };
    });
}

// TODO: Tidy up
const MenuContainer = () => {
    const {
        location: { pathname },
    } = useHistory();
    const id = pathname.split('moment/')[1] * 1; // Could be a better way of doing this, why can't we use match params here?
    const { loading, data } = useQuery(GET_MENU);
    const rawMoments = data ? data.home.grid : [];
    const categoryOrder = data ? data.home.categoryOrder : [];
    const moments = getMomentsFromCategories(categoryOrder, rawMoments);
    const prevId = usePrevious(id); // Store previous in a ref
    const [exitingId, setExitingId] = useState();

    const currMoment = moments[id - 1];

    useEffect(() => {
        if (prevId) {
            setExitingId(prevId);
        }
    }, [prevId]);

    // create object of categories and their respective counts
    const categories = buildCategories(categoryOrder, moments);

    const menuData = {
        exitingId,
        articles: data ? data.home.articles : [],
        categories,
        colour: currMoment
            ? getCounterColour(currMoment.theme.colour.hex)
            : namedColours.gold,
    };

    // TODO: Loading page (MAKE GLOBAL FIRST)
    return loading ? null : <Menu {...menuData} />;
};

export default MenuContainer;
