import React from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
// import { motion } from 'framer-motion';

const MenuIcon = styled.svg({});

const MenuIconComponent = ({ colour = '#fff', ...props }) => (
    <MenuIcon viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg" {...props}>
        <motion.path
            fill={colour}
            d="M53.4 20H6.6c-1.1 0-2-.9-2-2s.9-2 2-2h46.8c1.1 0 2 .9 2 2s-.9 2-2 2z"
        />
        <motion.path
            fill={colour}
            d="M53.4 32H6.6c-1.1 0-2-.9-2-2s.9-2 2-2h46.7c1.1 0 2 .9 2 2s-.8 2-1.9 2z"
        />
        <motion.path
            fill={colour}
            d="M53.5 44.9h-47c-1 0-1.9-.8-1.9-1.9 0-1 .8-1.9 1.9-1.9h47c1 0 1.9.8 1.9 1.9 0 1.1-.9 1.9-1.9 1.9z"
        />
    </MenuIcon>
);

export default MenuIconComponent;
