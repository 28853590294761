import React from 'react';
import { useQuery } from '@apollo/client';
import GET_HOME from 'data/queries/get-longform.graphql.js';
import Article from 'components/templates/article';
import SEO from 'utils/seo';

function getArticle(slug, articles) {
    return articles.find((item) => {
        return item.slug === slug;
    });
}

const LonformContainer = ({
    match: {
        url,
        params: { slug },
    },
}) => {
    const { loading, data } = useQuery(GET_HOME);
    const articles = data ? data.home.articles : [];

    if (loading) {
        return false;
        // TODO: Loading page (MAKE GLOBAL FIRST)
        // return <LoaderPage />;
    }

    const currArticle = getArticle(slug, articles);

    const longformSeo = {
        ...currArticle.seoMeta,
        backgroundColour: currArticle.theme.colour.hex,
    };

    const seoProps = {
        pageUrl: url,
        seoDefaults: data._site.globalSeo,
        pageSeo: longformSeo,
    };

    const longformProps = {
        article: currArticle,
    };

    // TODO: Loading page (MAKE GLOBAL FIRST)
    return (
        <>
            <SEO {...seoProps} />
            <Article {...longformProps} />
        </>
    );
};

export default LonformContainer;
