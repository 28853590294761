import React from 'react';
import styled from '@emotion/styled';
import { namedColours } from 'styles/colours';
import { articleTitle } from 'styles/text';
import { gutter, outterGutter } from 'styles/gutter';
import ReactMarkdown from 'react-markdown';
import { articleMarkdown } from 'styles/abstracts';
import { mq } from 'styles/breakpoints';
import { motion } from 'framer-motion';

const ArticleHero = styled(motion.div)(({ colour }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    color: namedColours.white,
    background: colour,
    height: 'var(--vh, 100vh)',
    willChange: 'transform',
}));

const HeroImage = styled(motion.div)({
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 1,
    perspective: '100vw',
    perspectiveOrigin: 'top',
    overflow: 'hidden',
});

const Illustration = styled(motion.img)({
    height: '65%',
});

const HeroText = styled(motion.div)(
    {
        position: 'relative',
        zIndex: 2,
        color: namedColours.white,

        [mq.large]: {
            maxWidth: '800px',
            marginLeft: '-25vw',
        },
    },
    outterGutter({ bottom: 1.5 })
);

const Title = styled(motion.h1)(
    articleTitle,
    {
        maxWidth: '600px',
    },
    gutter({ top: 0, right: 0, bottom: 0.5, left: 0 })
);
const Introduction = styled(ReactMarkdown)(
    articleMarkdown,
    {},
    gutter(
        { top: 0, right: 0, bottom: 0, left: 0 },
        { large: { top: 0, right: 0, bottom: 0, left: 1 } }
    )
);

const articleHeroVariants = {
    initial: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: {
            when: 'beforeChildren',
            staggerChildren: true,
        },
    },
    exit: {
        opacity: 0,
        transition: {
            delay: 1,
            duration: 3,
        },
    },
};
const heroTextVariants = {
    initial: { y: 500, opacity: 0 },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: 1.7, ease: [0, 1, 0.3, 1] },
    },
};
const heroImageVariants = {
    initial: { z: 1000, opacity: 0 },
    visible: {
        z: 0,
        opacity: 1,
        transition: { duration: 2, ease: [0.5, 0.5, 0, 1] },
    },
};

function ArticleHeroComponent({
    title,
    introduction,
    colour,
    heroImageUrl,
    heroImageAlt,
}) {
    return (
        <ArticleHero
            initial="initial"
            animate="visible"
            exit="exit"
            variants={articleHeroVariants}
            colour={colour}
        >
            <HeroImage>
                <Illustration
                    src={heroImageUrl}
                    alt={heroImageAlt}
                    variants={heroImageVariants}
                />
            </HeroImage>
            <HeroText variants={heroTextVariants}>
                <Title>{title}</Title>
                <Introduction>{introduction}</Introduction>
            </HeroText>
        </ArticleHero>
    );
}

export default ArticleHeroComponent;
