import React from 'react';
import styled from '@emotion/styled';
// import { motion } from 'framer-motion';

const CloseIcon = styled.svg({});

const CloseIconComponent = ({ colour = '#0a0606', ...props }) => (
    <CloseIcon
        viewBox="0 0 25 25"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M12.5 1.2C6.3 1.2 1.2 6.3 1.2 12.5c0 6.2 5.1 11.3 11.3 11.3 6.2 0 11.3-5.1 11.3-11.3 0-6.2-5.1-11.3-11.3-11.3zM19 16.8L16.8 19l-4.1-4.1L8.5 19l-2.2-2.2 4.2-4.2-4.2-4.1 2.2-2.2 4.2 4.2 4.1-4.2L19 8.5l-4.1 4.2 4.1 4.1z"
            fill={colour}
        />
    </CloseIcon>
);

export default CloseIconComponent;
