import React from 'react';
import { motion } from 'framer-motion';
import styled from '@emotion/styled';
import { namedColours } from 'styles/colours';
import { gutter } from 'styles/gutter';
// import randomNumber from 'utils/randomNumber';
import {
    gridTextCellContentIntro,
    gridTextCellContentOutro,
    gridTextCellIntro,
    gridTextCellOutro,
} from 'styles/animation';

const GridCellText = styled(motion.div)(
    {
        position: 'relative',
        zIndex: 2,
        width: '100%',
        height: '100%',
        perspective: '100vw',
    },
    ({ gridArea }) => ({
        gridArea: gridArea,
    })
);

const Background = styled(motion.div)({
    position: 'absolute',
    zIndex: 1,
    width: '100%',
    height: '100%',
    background: namedColours.white,
    willChange: 'transform',
});

// Note: if you want toggle display, you need to do this scale work on a nested item so the grid doesn't collapse...
// transitionEnd: {
//     display: 'none',
// },
const backgroundVariants = {
    initial: { scale: 0 },
    visible: {
        scale: 1,
        transition: {
            ...gridTextCellIntro,
        },
    },
    exit: {
        scale: 0,
        transition: {
            ...gridTextCellOutro,
        },
    },
};

const GridCellTextContent = styled(motion.div)(
    {
        position: 'relative',
        zIndex: 2,
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        willChange: 'transform',
    },
    gutter(
        { top: 1.25, bottom: 1 },
        { small: { top: 1.8, right: 0, bottom: 3, left: 0 } }
    )
);

const cellContentVariants = {
    initial: { opacity: 0, y: 5, display: 'none' },
    visible: {
        display: 'flex',
        opacity: 1,
        y: 0,
        transition: gridTextCellContentIntro,
    },
    exit: {
        opacity: 0,
        transition: gridTextCellContentOutro,
        transitionEnd: {
            display: 'none',
        },
    },
};

function GridCellTextComponent({ gridArea, children }) {
    return (
        <GridCellText gridArea={gridArea}>
            <Background
                initial="initial"
                animate="visible"
                exit="exit"
                variants={backgroundVariants}
            />
            <GridCellTextContent
                initial="initial"
                animate="visible"
                exit="exit"
                variants={cellContentVariants}
            >
                {children}
            </GridCellTextContent>
        </GridCellText>
    );
}

export default GridCellTextComponent;
